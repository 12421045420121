import React from 'react';
import { TextField } from '@mui/material';

const PhoneInput = ({ name, label, sendInput, isRequired, val }) => {
  return (
    <TextField
      required={isRequired}
      fullWidth
      type="tel"
      variant="outlined"
      name={name}
      value={val}
      onChange={(e) => sendInput(e.target.name, e.target.value)}
      label={label}
    />
  );
};

export default PhoneInput;
