import React, { useState } from 'react';

import { Box, Button, Dialog, DialogContent, Grid, Paper, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import UpdateUserGroupsForm from './forms/UpdateUserGroupsForm';
import CreateUserForm from './forms/CreateUser';
import UpdateUserForm from './forms/UpdateUserForm';

import StandardWrapper from './design/StandardWrapper';

const menuItems = [
  {
    category: 'Animal Tools',
    items: [
      { name: 'Place Animal Order', link: '/animal-order' },
      { name: 'Create Transfer Request', link: '/internal-transfer' },
      { name: 'Create Import Request', link: '/import' },
      { name: 'Create Export Request', link: '/export' },
      { name: 'Tissue Requests', link: 'https://app.smartsheet.com/b/form/c519267dbb32457eab484b280352d9c5' },
      {
        subcategory: 'USDA Fasting Requests',
        items: [
          { name: 'New Fasting Request', link: '/request-fast' },
          { name: 'View Existing Requests', link: '/fast-requests' },
        ],
      },
      {
        subcategory: 'Vivarium Compliance Tool',
        items: [
          { name: 'View reports', link: '/vct-dashboard' },
          { name: 'Create new report', link: '/vct-dashboard/?form', authGroups: ['admin', 'brms', 'brmsSuperuser'] },
        ],
      },
    ],
  },
  {
    category: 'Facility Tools',
    items: [
      { name: 'Resource Scheduler', link: '/resource-scheduler' },
      { name: 'Facilities Lookup', link: '/facilities', authGroups: ['facilitySupervisor', 'brmsSuperuser', 'admin'] },
      {
        subcategory: 'BRMS Supply Ordering',
        items: [
          { name: 'Order New Supplies', link: '/order-inventory', authGroups: ['admin', 'brmsSuperuser', 'brms'] },
          { name: 'View Existing Orders', link: '/inventory-orders', authGroups: ['admin', 'brmsSuperuser', 'brms'] },
          { name: 'Manage Inventory', link: '/inventory-items', authGroups: ['admin', 'brmsSuperuser'] },
        ],
        authGroups: ['admin', 'brmsSuperuser', 'brms'],
      },
      { name: 'BRMS Emergency Alert', link: '/emergency-alert', authGroups: ['admin', 'brmsSuperuser'] },

      {
        subcategory: 'Information',
        items: [
          { name: 'Safety Data Sheets', link: '/sds' },
          {
            name: 'Special Considerations',
            link: '/special-considerations',
            authGroups: ['husbandry', 'brms', 'brmsSuperuser', 'admin', 'facilitySupervisor'],
          },

          // { name: 'Manage Notifications', link: '/user-notifications' },
        ],
      },
      {
        name: 'Tech Time Slips',
        link: 'https://app.smartsheet.com/b/form/573a36975d2b4d77b507482158a9e941?confirm=true',
        authGroups: ['brms', 'brmsSuperuser', 'admin'],
      },
      { name: 'BRMS Feedback Portal', link: 'https://app.smartsheet.com/b/form/7a5c9c4f42eb427c8c8fb727865f38d8' },
      { name: 'Create Help Desk Ticket', link: 'https://uwsmph.teamwork.com/support' },
    ],
  },
  {
    category: 'Administrative Tools',
    items: [
      { name: 'Add Resource', link: '/add-resource', authGroups: ['admin', 'brmsSuperuser'] },
      { name: 'SAR Query', link: '/legacy-sar', authGroups: ['admin', 'brmsSuperuser'] },
      { name: 'DAR Query', link: '/legacy-dar', authGroups: ['admin', 'brmsSuperuser'] },
      {
        name: 'Custom Notifications',
        link: '/custom-notifications',
        authGroups: ['admin'],
      },
      {
        name: 'Notification Admin',
        link: '/notification-admin',
        authGroups: ['admin', 'superuser', 'brmsSuperuser'],
      },
      {
        subcategory: 'Users',
        items: [
          { name: 'Create User', form: 'CreateUserForm', authGroups: ['admin'] },
          { name: 'Update User', form: 'UpdateUserForm', authGroups: ['admin'] },
          { name: 'Authorize User', form: 'UpdateUserGroupsForm', authGroups: ['admin'] },
        ],
      },
      { name: 'Create API Key', link: '/create-api-key', authGroups: ['admin'] },
      {
        subcategory: 'Reporting',
        items: [
          { name: 'VCT Reports', link: '/vct-reporting', authGroups: ['admin'] },
          { name: 'SAR Reports', link: '/sar-reporting', authGroups: ['admin'] },
        ],
      },
    ],
    authGroups: ['admin', 'superuser', '', 'brmsSuperuser'],
  },
];

const userHasAuth = (authGroups, userGroups) => {
  return !authGroups || authGroups.some((auth) => userGroups.includes(auth));
};

const Home = ({ user }) => {
  const [activeForm, setActiveForm] = useState();

  const renderForm = () => {
    switch (activeForm) {
      case 'UpdateUserGroupsForm': {
        return <UpdateUserGroupsForm user={user} />;
      }
      case 'CreateUserForm': {
        return <CreateUserForm />;
      }
      case 'UpdateUserForm': {
        return <UpdateUserForm />;
      }
      default:
        return null;
    }
  };

  return (
    <StandardWrapper>
      <Grid container spacing={2} columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Animal Health and Welfare Tools</Typography>
        </Grid>

        {menuItems.map(
          (e, i) =>
            userHasAuth(e.authGroups, user.groups) && (
              <Grid item sm={12} md={6} key={i}>
                <Paper elevation={10} sx={{ bgcolor: '#DADFE180' }}>
                  <Grid container padding={1}>
                    <Grid item xs={12} padding="1em">
                      <Typography variant="h2">{e.category}</Typography>
                    </Grid>

                    {e.items.map(
                      (e, i) =>
                        userHasAuth(e.authGroups, user.groups) &&
                        (e.name ? (
                          <Grid item xs={12} key={i} padding=".25em">
                            <Button
                              key={i}
                              variant="contained"
                              fullWidth
                              href={e.link}
                              sx={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
                            >
                              {e.name}
                            </Button>
                          </Grid>
                        ) : (
                          <Paper
                            elevation={10}
                            key={i}
                            sx={{ padding: '.5em', margin: '.5em', backgroundColor: '#DADFE1', width: '100%' }}
                          >
                            <Grid
                              item
                              xs={12}
                              key={i}
                              // padding=".25em"
                              // border="1px solid #0479A8"
                              // borderRadius=".25em"
                              // margin=".25em"
                              container
                            >
                              <Grid item xs={12}>
                                <Typography variant="h3" textAlign="left" fontWeight="bold">
                                  {e.subcategory}
                                </Typography>
                              </Grid>

                              {e.items.map(
                                (e, i) =>
                                  userHasAuth(e.authGroups, user.groups) && (
                                    <Grid item xs={12}>
                                      <Button
                                        key={i}
                                        variant="contained"
                                        fullWidth
                                        href={e.link ? e.link : undefined}
                                        onClick={e.link ? undefined : () => setActiveForm(e.form)}
                                        sx={{ justifyContent: 'flex-start', marginBlock: '.25em', fontWeight: 'bold' }}
                                      >
                                        {e.name}
                                      </Button>
                                    </Grid>
                                  )
                              )}
                            </Grid>
                          </Paper>
                        ))
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )
        )}
        {process.env.NODE_ENV === 'development' && (
          <Grid item sm={12} md={6}>
            <Paper elevation={6} sx={{ bgcolor: '#DADFE1' }}>
              <Grid container padding={1}>
                <Grid item xs={12} padding="1em">
                  <Typography variant="h2">Developer Playground</Typography>
                </Grid>
                <Grid item xs={12} padding=".25em">
                  <Button
                    variant="contained"
                    fullWidth
                    href="/dev"
                    sx={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
                  >
                    Dev Tools
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog open={!!activeForm} onClose={() => setActiveForm()} fullWidth>
        <DialogContent>{renderForm()}</DialogContent>
      </Dialog>
    </StandardWrapper>
  );
};

export default Home;
