import { useEffect, useRef } from 'react';
import { AxisBottom } from '../../Components/AxisBottom';
import { AxisLeft } from '../../Components/AxisLeft';

import { scaleBand, scaleLinear } from 'd3-scale';
import { max, sum } from 'd3-array';
import { useState } from 'react';
import { dimensionDefaults, countStatusesPerProtocol, exportCSV } from '../../Components/utils';
import { Box, Button, Typography } from '@mui/material';
import { groupSort, union, index, stack } from '../d3Utils'; // Copy pasted from d3 version 7

export const ResolutionsByProtocol = ({ data, dateFrom, dateTo, numcolumns, viewfilter }) => {
  /* STATE */
  // Data variables
  const [{ innerWidth, width, height, margin, yAxisWidth, innerHeight, xAxisHeight, xAxisLabelOffset }, setDimensions] =
    useState(dimensionDefaults);

  const [viewData, setViewData] = useState(data);
  const [exportData, setExportData] = useState([]);

  // D3 Scales
  const [xScale, setXScale] = useState(() => {});
  const [yScale, setYScale] = useState(() => {});

  const ref = useRef(null);

  useEffect(() => {
    const vrbpData = countStatusesPerProtocol(data);
    // Stacked Bar Chart Data
    setExportData(vrbpData);

    const innerHeight = (vrbpData.length / 4) * 30;
    const width = (ref.current ? ref.current.parentElement.offsetWidth : 500) - 10;
    const innerWidth = width - margin.left - margin.right - yAxisWidth;
    setDimensions((prev) => ({
      ...prev,
      innerHeight,
      height:
        innerHeight +
        prev.margin.top +
        prev.margin.bottom +
        prev.xAxisHeight +
        prev.xAxisLabelOffset +
        prev.legendOffset,
      width,
      innerWidth,
    }));

    // Divide into series bars
    const series = stack()
      .keys(union(vrbpData.map((d) => d.status)))
      .value(([, D], key) => D.get(key).count)(
      index(
        vrbpData,
        (d) => d.protocolLabel,
        (d) => d.status
      )
    );
    setViewData(series);

    // Define Stacked Bar Chart Scale Dimensions
    setXScale(() =>
      scaleLinear()
        .domain([0, max(series, (d) => max(d, (d) => d[1]))])
        .range([0, innerWidth])
    );

    setYScale(() =>
      scaleBand()
        .domain(
          groupSort(
            vrbpData,
            (D) => -sum(D, (d) => d.count),
            (d) => d.protocolLabel
          )
        )
        .range([0, innerHeight])
        .padding(0.1)
    );
  }, [data, numcolumns, viewfilter]);

  const color = (key) => {
    if (!key) return '#ADADAD';

    switch (key) {
      case 'Resolved overdue':
        return '#C5050C';

      case 'Resolved on time':
        return '#FCCB51';

      case 'Resolved by BRMS staff':
        return '#8DD4CE';

      case 'Unresolved':
        return '#ADADAD';

      default:
        return '#ADADAD';
    }
  };

  /* RENDER */
  return (
    <Box display="flex" flexDirection="column" alignItems="center" ref={ref}>
      <Box display="flex" justifyContent="space-between" width="90%" padding=".5em">
        <Typography variant="h5">VCR Resolutions by Protocol</Typography>
        <Button
          onClick={() => exportCSV('resolutionsByProtocol', exportData, dateFrom, dateTo)}
          variant="contained"
          size="small"
        >
          Export
        </Button>
      </Box>
      {xScale && yScale && viewData && (
        <svg width={width} height={height} style={{ backgroundColor: 'DADFE1', overflow: 'visible' }}>
          <g transform={`translate(${margin.left + yAxisWidth},${margin.top})`}>
            {/* Left Axis */}
            <AxisLeft yScale={yScale} />

            {/* Bottom Axis */}
            <text
              x={innerWidth / 2}
              y={innerHeight + xAxisHeight + xAxisLabelOffset}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="1.25em"
            >
              Number of Compliance Reports
            </text>
            <AxisBottom xScale={xScale} innerHeight={innerHeight} />

            {/* Data */}

            {viewData.map((status, i) => {
              if (Array.isArray(status))
                return (
                  <g key={i} fill={color(status.key)}>
                    {status.map((bar, j) => (
                      <rect
                        x={xScale(bar[0])}
                        y={yScale(bar.data[0])}
                        width={xScale(bar[1]) - xScale(bar[0]) || 0}
                        height={yScale.bandwidth()}
                        key={j}
                      >
                        <title>{`${bar.data[0]}\n ${status.key}: ${bar.data[1].get(status.key).count}`}</title>
                      </rect>
                    ))}
                  </g>
                );
            })}
          </g>
          {/* Legend */}
          <g transform={`translate(10,${height - xAxisHeight - xAxisLabelOffset - 10})`}>
            <Legend />
          </g>
        </svg>
      )}
    </Box>
  );
};

const Legend = () => {
  const barWidth = 130;
  const barHeight = 20;
  const padding = 3;

  const legendData = [
    { key: 'Resolved on time', barColor: '#FCCB51', color: '#000000' },
    { key: 'Resolved by BRMS Staff', barColor: '#8DD4CE', color: '#000000' },
    { key: 'Overdue', barColor: '#C5050C', color: '#FFFFFF' },
    { key: 'Unresolved', barColor: '#ADADAD', color: '#000000' },
  ];
  return (
    <>
      <text fontSize=".9rem" textAnchor="left" dominantBaseline="hanging" x="10">
        Legend
      </text>
      {legendData.map((item, i) => (
        <g key={i} transform={`translate(${i * (barWidth + padding)}, 20)`}>
          <rect x="0" y="0" width={barWidth} height={barHeight} fill={item.barColor} />
          <text
            x={barWidth / 2}
            y={barHeight / 2}
            fontSize=".8rem"
            textAnchor="middle"
            dominantBaseline="middle"
            fill={item.color}
          >
            {item.key}
          </text>
        </g>
      ))}
    </>
  );
};
