import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const SelectInput = ({ menu, label, required, onChange, val = '', displayEmpty = false }) => {
  val = !val ? '' : val;

  return (
    <FormControl variant="outlined" fullWidth required={required}>
      <InputLabel>{label}</InputLabel>
      <Select
        onChange={onChange}
        label={label}
        value={val}
        required={required}
        displayEmpty={displayEmpty}
        sx={{ textAlign: 'left' }}
      >
        {menu.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
