import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Divider,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
  Alert,
  AlertTitle,
  LinearProgress,
} from '@mui/material';

import StandardWrapper from './design/StandardWrapper';
import { useQuery, useMutation } from '@apollo/client';

import { gql } from '@apollo/client';
import moment from 'moment';

import { KeyboardArrowLeft } from '@mui/icons-material';
import { buildingCodeToName } from '../utils';

const GET_ORDER = gql`
  query InventoryOrder($number: Int!) {
    inventoryCartByNumber(number: $number) {
      items {
        quantity
        item {
          _id
          name
          unit
          vendor
          code
          description
        }
      }
      user {
        PVI
        firstName
        lastName
      }
      completedBy {
        PVI
        firstName
        lastName
      }
      dateSubmitted
      dateCompleted
      status
      _id
      number
      building
    }
  }
`;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const COMPLETE_CART = gql`
  mutation ($_id: ID!) {
    completeInventoryCart(_id: $_id) {
      _id
      status
    }
  }
`;

export default function InventoryOrder({ user, number: numberProp }) {
  const params = useParams();
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);

  const { number: numberParam } = params;
  const getNumber = () => {
    if (numberProp) {
      if (typeof parseInt(numberProp) === 'number') {
        return parseInt(numberProp);
      }
      console.error('invalid number passed as prop to InventoryOrder');
    }
    if (numberParam) {
      if (typeof parseInt(numberParam) === 'number') {
        return parseInt(numberParam);
      }
      console.error('invalid number passed as param to InventoryOrder');
    }
    throw new Error('No valid order number passed to inventoryOrder component');
  };

  const {
    loading: orderLoading,
    error,
    data,
    refetch,
  } = useQuery(GET_ORDER, {
    variables: {
      number: getNumber(),
    },
  });
  const [createError] = useMutation(CREATE_ERROR);
  const [completeCart] = useMutation(COMPLETE_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'completeInventoryCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast(true);
        refetch();
      } else setToast(false);
    },
  });

  if (orderLoading) {
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  if (error || (!loading && !data)) {
    console.log('error: ', error);

    return (
      <StandardWrapper>
        <Typography variant="h4">Could not display order.</Typography>
      </StandardWrapper>
    );
  }

  console.log('data: ', data);
  const { inventoryCartByNumber: cart } = data;
  if (!cart || !Array.isArray(cart.items)) {
    return (
      <StandardWrapper>
        <Typography variant="h4">Could not display order.</Typography>
      </StandardWrapper>
    );
  }
  const { items, dateCompleted, dateSubmitted, status, number, user: submittedBy, completedBy, _id, building } = cart;

  const formatDate = (date) => {
    if (!date) return null;
    return moment(date).format('MM/DD/YYYY hh:mm a');
  };

  const getName = (_user) => {
    if (!_user) return null;
    const { firstName, lastName } = _user;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) return firstName;
    if (lastName) return lastName;
    return null;
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const canComplete =
    status !== 'completed' && ['admin', 'brmsSuperuser'].some((group) => user.groups.includes(group)) && !!_id;

  const handleComplete = () => {
    if (canComplete) {
      completeCart({ variables: { _id } });
    } else {
      setErrorDialog(true);
      console.log('cant complete operation');
    }
  };
  return (
    <StandardWrapper>
      <Typography variant="h1">Order #{number}</Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back to order table"
            onClick={() => {
              window.location.href = '/inventory-orders';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>

        <Grid item xs={12} padding="2em 4em" textAlign="left">
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Building: </span>
            {buildingCodeToName(building) || building}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Status: </span>
            {capitalize(status)}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Date Submitted: </span>
            {formatDate(dateSubmitted)}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Submitted By: </span>
            {getName(submittedBy)}
          </Typography>
          {status === 'completed' ? (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Date Completed: </span>
              {formatDate(dateCompleted)}
            </Typography>
          ) : (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Completed By: </span>
              {getName(completedBy)}
            </Typography>
          )}
        </Grid>

        <Grid xs={12}>
          <Typography variant="h4">Items Ordered</Typography>
        </Grid>

        {items.map(({ item: { name, unit, vendor, code, _id, description }, quantity }, i) => {
          return (
            <Grid item key={i} padding="1em 5em" xs={12} textAlign="left">
              <Divider />

              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>Item Name: </span>
                {name}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>Quantity: </span>
                {quantity}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>Unit: </span>
                {unit}
              </Typography>
              {vendor && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold' }}>Vendor: </span>
                  {vendor}
                </Typography>
              )}
              {code && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold' }}>Code: </span>
                  {code}
                </Typography>
              )}
              {description && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold' }}>Vendor: </span>
                  {description}
                </Typography>
              )}
            </Grid>
          );
        })}
        {canComplete && (
          <Grid container justifyContent="flex-end" style={{ marginTop: 16 }}>
            <Grid item>
              <Button
                style={{ margin: 18, color: 'black' }}
                variant="outlined"
                // color="primary"
                type="submit"
                onClick={handleComplete}
              >
                Mark as Completed
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Order Marked As Complete!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to mark order as complete. BRMS IT has been alerted of the issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
}
