import React, { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import ALL_BUILDINGS from '../../constants/buildings';

const BuildingSearch = ({ name, sendInput, val = null, required = true, label, freeSolo = false }) => {
  const handleSelect = (value) => sendInput(name, value);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={ALL_BUILDINGS.map((x) => x.name)}
      value={val}
      onChange={(e, value) => handleSelect(value)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required={required}
            placeholder="Enter building"
            label={label || 'Building'}
            variant="outlined"
            fullWidth
          />
        );
      }}
    />
  );
};

export default BuildingSearch;
