import { useState, useEffect, useRef } from 'react';
import { arc, pie } from 'd3';
import { dimensionDefaults, ocrReasons, exportCSV } from '../../Components/utils';
import { Box, Button, Typography } from '@mui/material';

export const ReasonComparison = ({ data, dateFrom, dateTo, numcolumns, viewfilter }) => {
  const [{ height, width, margin }, setDimensions] = useState(dimensionDefaults);
  const [viewData, setViewData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    const newReasonData = ocrReasons(data).map((d) => ({
      ...d,
      percent: Math.round((d.count / data.length) * 1000) / 10,
    }));
    setViewData(newReasonData);
    setExportData(newReasonData);

    const newWidth = ref.current ? ref.current.parentElement.offsetWidth : 500;
    const newHeight = Math.min(newWidth, height);
    setDimensions((prev) => ({
      ...prev,
      width: newWidth - 10,
      height: newHeight,
    }));
  }, [data, numcolumns, viewfilter]);

  const radius = Math.min(width, height) / 2;

  const arcWidth = arc()
    .innerRadius(radius * 0.25)
    .outerRadius(radius - margin.left);

  const pieSetup = pie()
    .padAngle(1 / radius)
    .sort(null)
    .value((d) => d.count);

  const color = (key) => {
    let result;
    switch (key) {
      case 'Double litter':
        result = '#8080FF';
        break;
      case 'Litter was not weaned in a timely manner':
        result = '#80FF80';
        break;
      case 'Too many adults':
        result = '#FF8080';
        break;
      case 'Too many adults and a litter':
        result = '#FF80FF';
        break;

      default:
        result = '#ADADAD';
    }
    return result;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" ref={ref}>
      <Box display="flex" justifyContent="space-between" width="90%" padding=".5em">
        <Typography variant="h5">VCR Resolutions by Protocol</Typography>
        <Button
          onClick={() => exportCSV('resolutionsByProtocol', exportData, dateFrom, dateTo)}
          variant="contained"
          size="small"
        >
          Export
        </Button>
      </Box>
      <svg width={width} height={height} style={{ backgroundColor: '#DADFE1', overflow: 'visible' }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {pieSetup(viewData).map((d, i) => (
            <g key={i}>
              <path fill={color(d.data.reason)} d={arcWidth(d)} key={i}>
                <title>{`Reason type: ${d.data.reason}\nNumber: ${d.data.count}: ${d.data.percent}%`}</title>
              </path>
            </g>
          ))}
          {pieSetup(viewData).map((d, i) => (
            <g
              key={i}
              textAnchor="middle"
              transform={`translate(${arcWidth.centroid(d).map((e) => (e * (i + 1)) / 3)})`}
            >
              <text fontWeight="bold">{d.data.reason}</text>

              <text dy="1.25em">{`${d.data.count} reports: ${d.data.percent}%`} </text>
            </g>
          ))}
        </g>
      </svg>
    </Box>
  );
};
