import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Autocomplete,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  createFilterOptions,
  Alert,
  AlertTitle,
} from '@mui/material';

import ALL_BUILDINGS from '../../constants/buildings';

import { SMPHAnimals } from '../inputs/SpeciesSearch';
import { useMutation, useQuery } from '@apollo/client';

import EmailList from '../inputs/EmailList';
import TableSwitch, { isUSDAAnimal } from '../inputs/TableSwitch';
import FormDivider from '../design/FormDivider';
import SelectInput from '../inputs/SelectInput';

import TransferContainerSection from '../inputs/TransferContainerSection';
import { sleep, buildingNameToCode } from '../../utils';

import StandardWrapper from '../design/StandardWrapper';
import { GET_TOASTS } from '../../global/graphql';

const GET_ALL_PROTOCOL_OUTLINES = gql`
  {
    allProtocolOutlines {
      _id
      protocolNumber
      label
      PIPVI
      buildings
      species
    }
  }
`;
const SUBMIT_IMPORT = gql`
  mutation SubmitImport(
    $receivingProtocolNumber: String!
    $receivingBuilding: String!
    $receivingRoom: String!
    $receivingPI: String
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String
    $receivingBillingString: String!
    $mtaStatus: String!
    $ackQuarantine: Boolean!
    $sendingInstitution: String!
    $sendingPI: String!
    $sendingContactName: String!
    $sendingContactEmail: String!
    $sendingTransferCoordinatorName: String
    $sendingTransferCoordinatorEmail: String
    $sendingVetName: String
    $sendingVetEmail: String
    $bsl: Int
    $biohazardAgent: String
    $immunodeficient: Boolean!
    $feed: String!
    $housing: String!
    $addlRequirements: String
    $species: String!
    $numMales: Int
    $numFemales: Int
    $numCages: Int
    $strain: String
    $animals: String
    $addlEmails: String
    $dateRequired: String
    $submitterEmail: String!
    $naive: Boolean
    $submitterPhone: String
  ) {
    submitImport(
      receivingProtocolNumber: $receivingProtocolNumber
      receivingBuilding: $receivingBuilding
      receivingRoom: $receivingRoom
      receivingContactName: $receivingContactName
      receivingPI: $receivingPI
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingBillingString: $receivingBillingString
      mtaStatus: $mtaStatus
      ackQuarantine: $ackQuarantine
      sendingInstitution: $sendingInstitution
      sendingPI: $sendingPI
      sendingContactName: $sendingContactName
      sendingContactEmail: $sendingContactEmail
      sendingTransferCoordinatorName: $sendingTransferCoordinatorName
      sendingTransferCoordinatorEmail: $sendingTransferCoordinatorEmail
      sendingVetName: $sendingVetName
      sendingVetEmail: $sendingVetEmail
      bsl: $bsl
      biohazardAgent: $biohazardAgent
      immunodeficient: $immunodeficient
      housing: $housing
      feed: $feed
      addlRequirements: $addlRequirements
      species: $species
      numMales: $numMales
      numFemales: $numFemales
      numCages: $numCages
      strain: $strain
      animals: $animals
      addlEmails: $addlEmails
      dateRequired: $dateRequired
      submitterEmail: $submitterEmail
      submitterPhone: $submitterPhone
      naive: $naive
    ) {
      success
      message
    }
  }
`;

const policyMsg =
  'I understand the current quarantine policy and that my lab is responsible for all associated costs incurred with receiving these animals (shipping, quarantine testing fees and daily per diems; including treatment or rederivation if required). Contact your RARC Facility Veterinarian with questions.';

const ImportForm = ({ user }) => {
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateCheckDialog, setDateCheckDialog] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [receivingReset, setReceivingReset] = useState(false);
  const [userInfo] = useState(user);
  const [breedingCore, setBreedingCore] = useState(false);
  const [vars, setVars] = useState({
    immunodeficient: false,
    ackQuarantine: false,
    submitterEmail: userInfo.emailAddress,
    naive: false,
    receivingProtocolNumber: '',
    receivingBuilding: '',
    receivingRoom: '',
    receivingContactEmail: '',
    receivingContactName: '',
    species: '',
  });
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);

  const [sendingSave, setSendingSave] = useState();
  const [receivingSave, setReceivingSave] = useState();
  const [validationDialogMessage, setValidationDialogMessage] = useState(false);
  const [pigWarning, setPigWarning] = useState(false);
  const [pigWarningAcknowledged, setPigWarningAcknowledged] = useState(false);
  const [USDA, setUSDA] = useState(isUSDAAnimal(vars.species ? vars.species : '').isUSDA);
  const [infoToasts, setInfoToasts] = useState([]);
  const [protocol, setProtocol] = useState();
  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: 'import' },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map((x) => ({ ...x, active: true })));
    }
  }, [toastsLoading, toastData?.toasts]);

  const disableInfoToast = (_id) => {
    setInfoToasts(
      infoToasts.map((x) => {
        if (_id === x._id) {
          return { ...x, active: false };
        }
        return { ...x };
      })
    );
  };

  // End info toast logic

  const resetForm = () => {
    const _vars = {};
    Object.keys(vars).forEach((key) => {
      _vars[key] = '';
    });

    if (!sendingSave) {
      sendingReset ? setSendingReset(false) : setSendingReset(true);
    } else {
      _vars.sendingContactName = vars.sendingContactName;
      _vars.sendingContactEmail = vars.sendingContactEmail;
      _vars.sendingContactPhone = vars.sendingContactPhone;
      _vars.sendingInstitution = vars.sendingInstitution;
      _vars.sendingPI = vars.sendingPI;
      _vars.sendingTransferCoordinatorName = vars.sendingTransferCoordinatorName;
      _vars.sendingTransferCoordinatorEmail = vars.sendingTransferCoordinatorEmail;
      _vars.sendingTransferCoordinatorPhone = vars.sendingTransferCoordinatorPhone;
    }
    if (!receivingSave) {
      receivingReset ? setReceivingReset(false) : setReceivingReset(true);
    } else {
      _vars.receivingProtocolNumber = vars.receivingProtocolNumber;
      _vars.receivingBuilding = vars.receivingBuilding;
      _vars.receivingRoom = vars.receivingRoom;
      _vars.receivingContactName = vars.receivingContactName;
      _vars.receivingContactEmail = vars.receivingContactEmail;
      _vars.receivingContactPhone = vars.receivingContactPhone;
      _vars.receivingBillingString = vars.receivingBillingString;
      _vars.receivingDateRequired = vars.receivingDateRequired;
    }

    formReset ? setFormReset(false) : setFormReset(true);
    setVars({
      ..._vars,
      immunodeficient: false,
      ackQuarantine: false,
      submitterEmail: userInfo.emailAddress,
      submitterPhone: '',
      naive: false,
    });
    setBreedingCore(false);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addImport] = useMutation(SUBMIT_IMPORT, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitImport',
          error: err ? JSON.stringify(err) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });
  const { loading: protocolLoading, error: protocolError, data: protocolData } = useQuery(GET_ALL_PROTOCOL_OUTLINES);
  const protocols = protocolData?.allProtocolOutlines;

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDateCheckDialog(false);
    setDateWeekendDialog(false);
    setToast(false);
    setErrorDialog(false);
    setValidationDialogMessage();
    setPigWarning(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const removeContainerFields = () => {
    delete vars.numMales;
    delete vars.numFemales;
    delete vars.numCages;
    delete vars.strain;
  };

  const validateTable = () => {
    if (USDA.isUSDA) {
      if (!vars.animals || !Array.isArray(JSON.parse(vars.animals)) || JSON.parse(vars.animals).length < 1) {
        setValidationDialogMessage('You must add an animal in the table');
        return false;
      }
    }
    // if (!vars.dateRequired) {
    //     setValidationDialogMessage('Please select a different transfer date')
    //     return false;
    // }
    // if (!vars.sendingContactPhone) {
    //     setValidationDialogMessage('Please enter a phone number for the contact from the sending institution')
    //     return false;
    // }
    if (!vars.mtaStatus) {
      setValidationDialogMessage('Please select an MTA status');
      return false;
    }
    if (!vars.housing) {
      setValidationDialogMessage('Please select housing requirements');
      return false;
    }
    if (!vars.feed) {
      setValidationDialogMessage('Please select a feed type');
      return false;
    }
    // if (!vars.bsl) {
    //     setValidationDialogMessage('Please select a biosafety level')
    //     return false;
    // }
    return true;
  };

  const addNamedContact = (name, email, origin) => {
    let flag = false;

    setNamedContacts((prevState) => {
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i].origin === origin) {
          prevState[i].name = name;
          prevState[i].email = email;
          flag = true;
          break;
        }
      }

      if (!flag) {
        prevState.push({
          name: name,
          email: email,
          origin: origin,
        });
      }

      return prevState;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateTable()) {
      return;
    }
    setLoading(true);
    sleep(500);
    isUSDAAnimal(vars.species).isUSDA ? removeContainerFields() : delete vars.animals;
    const variables = { ...vars, receivingProtocolNumber: vars.receivingProtocolNumber?.protocolNumber };
    if (buildingNameToCode(variables.receivingBuilding)) {
      variables.receivingBuilding = buildingNameToCode(variables.receivingBuilding);
    }

    addImport({ variables });
  };
  return (
    <StandardWrapper>
      <Typography variant="h1">Imports</Typography>
      <form
        id="import-form"
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container alignContent="center" justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <FormDivider text="Instructions" />
          </Grid>

          <Grid item xs={12}>
            <Stack textAlign="left" spacing={3}>
              <Typography variant="p" fontWeight="bold">
                &bull; If a Material Transfer Agreement (MTA) is required by the sending institution, please contact the{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  target="_blank"
                  href="https://www.rsp.wisc.edu/contracts/mta.cfm"
                  rel="noreferrer"
                >
                  Office of Research and Sponsored Programs
                </a>
                .
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; Prior to completing this form, have the name and email addresses of the sending contacts ready.
                Including the transfers coordinator and facility veterinarian at the sending institution.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; Please review the{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  href="https://www.rarc.wisc.edu/tools_and_guides/animal_transfer.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  RARC Animal Transfers page
                </a>{' '}
                for information on the Import process.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull;{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  href="https://www.rarc.wisc.edu/services/rodent_quarantine.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Quarantine policies
                </a>{' '}
                are facility dependent; please contact your{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  target="_blank"
                  href="https://www.rarc.wisc.edu/contacts.html"
                  rel="noreferrer"
                >
                  RARC facility veterinarian
                </a>{' '}
                for current requirements and subsequent costs.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; All animals for import are assumed to be BSL1. If animals will be inoculated with any Biohazard
                and/or Chemical Hazard <em>prior to shipment</em> you must include details below in "Special
                Requirements".
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Submitter Information" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Email"
              placeholder="Enter email"
              value={'submitterEmail' in vars ? vars.submitterEmail : userInfo.emailAddress}
              onChange={(e) => {
                addNamedContact(userInfo.firstName + ' ' + userInfo.lastName, e.target.value, 'submitter');
                handleInput('submitterEmail', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Spacer */}
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Receiving Information (UW)" />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{ width: '100%' }}
              className="checkbox"
              control={
                <Checkbox
                  checked={breedingCore}
                  onChange={() => {
                    if (!breedingCore) {
                      setVars((vars) => ({
                        ...vars,
                        receivingProtocolNumber: protocols?.find((options) => options.protocolNumber === 'M005751'),
                        receivingBuilding: 'SMI Bardeen',
                        receivingRoom: '658b',
                        // receivingPI: 'Jody Peter',
                        receivingContactEmail: 'brms-rs-mouse@g-groups.wisc.edu',
                        species: 'Mouse',
                      }));
                    } else {
                      setVars((vars) => ({
                        ...vars,
                        receivingProtocolNumber: '',
                        receivingBuilding: '',
                        receivingRoom: '',
                        receivingPI: '',
                        receivingContactEmail: '',
                        species: '',
                      }));
                    }
                    setBreedingCore((prev) => !prev);
                  }}
                />
              }
              label="Check here if the animals are going into the Mouse Breeding Core"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Protocol Search */}
            <Autocomplete
              filterOptions={createFilterOptions({ limit: 5 })}
              options={protocols ? protocols : ['loading']}
              // getOptionLabel={(option) => option.label}
              value={vars.receivingProtocolNumber || ''}
              onChange={(e, value) => {
                handleInput('receivingProtocolNumber', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  placeholder="Enter protocol"
                  label="Protocol"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={ALL_BUILDINGS.map((x) => x.name)}
              value={vars.receivingBuilding}
              onChange={(e, value) => handleInput('receivingBuilding', value)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    required
                    placeholder="Enter building"
                    fullWidth
                    label="Building"
                    variant="outlined"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Animal Housing Room"
              placeholder="Enter room #"
              value={vars.receivingRoom}
              onChange={(e) => handleInput('receivingRoom', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Lab Contact Name"
              placeholder={vars.receivingContactName ? '' : 'Enter name'}
              value={vars.receivingContactName}
              onChange={(e) => {
                if (vars.receivingContactEmail)
                  addNamedContact(e.target.value, vars.receivingContactEmail, 'receiving');
                handleInput('receivingContactName', e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Lab Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.receivingContactEmail}
              onChange={(e) => {
                if (vars.receivingContactName) addNamedContact(vars.receivingContactName, e.target.value, 'receiving');
                handleInput('receivingContactEmail', e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              required
              label="MTA Status"
              key={formReset}
              val={vars.mtaStatus || ''}
              onChange={(e) => handleInput('mtaStatus', e.target.value)}
              menu={[
                { value: 'submitted', text: 'Submitted' },
                { value: 'approved', text: 'Approved/Signed' },
                { value: 'notRequired', text: 'Not Required by Recipent' },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Billing String"
              placeholder="Enter billing string"
              value={vars.receivingBillingString}
              onChange={(e) => handleInput('receivingBillingString', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {breedingCore && (
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                fullWidth
                label="Receiving PI Name"
                placeholder="Enter name"
                value={vars.receivingPI}
                onChange={(e) => handleInput('receivingPI', e.target.value)}
              />
            )}
          </Grid>

          <Grid item xs={12} textAlign="left">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={receivingReset}
                  checked={vars.ackQuarantine}
                  onChange={(e) => handleInput('ackQuarantine', e.target.checked)}
                ></Checkbox>
              }
              label={<span style={{ fontSize: '12px' }}>{policyMsg}</span>}
            />
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Sending Information (Other Institution)" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Institution"
              placeholder="Enter name of institution"
              value={vars.sendingInstitution}
              onChange={(e) => handleInput('sendingInstitution', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending PI Name"
              placeholder="Enter name"
              value={vars.sendingPI}
              onChange={(e) => handleInput('sendingPI', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Lab Contact Name"
              placeholder="Enter name"
              value={vars.sendingContactName}
              onChange={(e) => {
                if (vars.sendingContactEmail) addNamedContact(e.target.value, vars.sendingContactEmail, 'sending');
                handleInput('sendingContactName', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Lab Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.sendingContactEmail}
              onChange={(e) => {
                if (vars.sendingContactName) addNamedContact(vars.sendingContactName, e.target.value, 'sending');
                handleInput('sendingContactEmail', e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Transfer Coordinator Name"
              placeholder="Enter name"
              required
              value={vars.sendingTransferCoordinatorName}
              onChange={(e) => handleInput('sendingTransferCoordinatorName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Transfer Coordinator Email"
              placeholder="Enter email"
              type="email"
              required
              value={vars.sendingTransferCoordinatorEmail}
              onChange={(e) => handleInput('sendingTransferCoordinatorEmail', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Facility Vet Name"
              placeholder="Enter name"
              value={vars.sendingVetName}
              onChange={(e) => handleInput('sendingVetName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Facility Vet Email"
              placeholder="Enter email"
              type="email"
              value={vars.sendingVetEmail}
              onChange={(e) => handleInput('sendingVetEmail', e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Animal Information" />
          </Grid>

          <Grid container item xs={12} sm={6}>
            <Grid item xs={12}>
              <Autocomplete
                options={SMPHAnimals}
                value={vars.species}
                onChange={(e, value) => handleInput('species', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="Enter species"
                    label="Species"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} textAlign="left">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    key={formReset}
                    checked={vars.immunodeficient}
                    onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                  />
                }
                label="Immunocompromised"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel>Have these animals been used in a previous study?</FormLabel>
              <RadioGroup
                onChange={(e) => handleInput('naive', e.target.value === 'Yes' ? false : true)}
                defaultValue={'Yes'}
                name="radio-buttons-group"
              >
                <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                <FormControlLabel value={'No'} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              required
              label="Housing Requirements"
              key={formReset}
              val={vars.housing || ''}
              onChange={(e) => handleInput('housing', e.target.value)}
              menu={[
                { value: 'aseptic', text: 'Aseptic' },
                { value: 'conventional', text: 'Conventional' },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              required
              label="Feed Type"
              key={formReset}
              val={vars.feed || ''}
              onChange={(e) => handleInput('feed', e.target.value)}
              menu={[
                { value: 'maintenance', text: 'Maintenance' },
                { value: 'breeder', text: 'Breeder' },
                { value: 'Special', text: 'Special' },
              ]}
            />
          </Grid>

          {
            /* Toggle manual USDA selection vs. no manual USDA selection */
            USDA.found ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} textAlign="left">
                  <FormControl>
                    <FormLabel> Are these individually identifiable USDA animals? </FormLabel>
                    <RadioGroup
                      row
                      onChange={(e) => {
                        setUSDA({ found: false, isUSDA: e.target.value === 'true' });
                        // console.log(USDA);
                      }}
                    >
                      <FormControlLabel
                        className="checkbox"
                        control={<Radio checked={USDA.isUSDA} />}
                        label="Yes"
                        value="true"
                      />
                      <FormControlLabel
                        className="checkbox"
                        control={<Radio checked={!USDA.isUSDA} />}
                        label="No"
                        value="false"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )
          }
          <TableSwitch
            key={formReset + 'table-switch'}
            sendInput={handleInput}
            species={vars.species ? vars.species : ''}
            val={vars.animals}
            allRequired={true}
            USDAStatus={USDA}
            setUSDAStatus={setUSDA}
          >
            <Grid item xs={12}>
              <FormDivider text="Cages / Tanks" />
            </Grid>

            <Grid item xs={12}>
              <TransferContainerSection
                key={formReset + 'transfer-container'}
                sendInput={handleInput}
                val={vars.containers}
                strainRequired={true}
                width="85%"
              />
            </Grid>
          </TableSwitch>
          <Grid item xs={12}>
            <FormDivider text="Other Contacts" />
          </Grid>

          <Grid item xs={12}>
            <EmailList
              key={formReset}
              sendInput={handleInput}
              existingContacts={namedContacts}
              instructions={
                'A copy of this document will be sent to the contacts provided above. You may add email addresses for additional contacts from sending or receiving groups.'
              }
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              key={formReset}
              variant="outlined"
              label="Special Requirements / Other Information"
              placeholder="Enter additional comments/instructions"
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              value={vars.addlRequirements}
              onChange={(e) => handleInput('addlRequirements', e.target.value)}
            />
          </Grid>

          <Grid item xs={10}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ width: '10em', background: '#c5050c', float: 'right', margin: '1em' }}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Import has been submitted!
        </Alert>
      </Snackbar>

      <Dialog open={dateCheckDialog} onClose={handleToastClose}>
        <DialogTitle>{'Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A notice of 8 business days is preferred in advance of an import. Contact the veterinarian if this transfer
            is urgent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Imports cannot be requested to arrive on weekends.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The import failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={validationDialogMessage} onClose={handleToastClose}>
        <DialogTitle>{'Form not Completed'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pigWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A disposal fee of $150 per animal will be charged if BRMS is responsible for carcass disposal of an animal
            coming from outside the SMPH.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPigWarningAcknowledged(true);
              handleToastClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map((toast) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">{toast.message}</Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default ImportForm;
