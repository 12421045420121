import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import RedHatText from './static/fonts/Red hat text/RedHatText-Regular.ttf';
import RedHatDisplay from './static/fonts/Red hat display/RedHatDisplay-Regular.ttf';
import { indigo, purple, teal, green } from '@mui/material/colors';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#c5050c',
        contrastText: '#fff',
      },

      secondary: {
        main: '#0479a8',
      },

      resource1: { main: indigo[400], contrastText: '#fff' },
      resource2: { main: purple[400], contrastText: '#fff' },
      resource3: { main: indigo[600], contrastText: '#fff' },
      resource4: { main: purple[600], contrastText: '#fff' },
      resource5: { main: indigo[800], contrastText: '#fff' },
      resource6: { main: purple[800], contrastText: '#fff' },
      resource7: { main: indigo[900], contrastText: '#fff' },
      resource8: { main: purple[900], contrastText: '#fff' },

      protocol1: { main: teal[400], contrastText: '#fff' },
      protocol2: { main: green[400], contrastText: '#fff' },
      protocol3: { main: teal[600], contrastText: '#fff' },
      protocol4: { main: green[600], contrastText: '#fff' },
      protocol5: { main: teal[800], contrastText: '#fff' },
      protocol6: { main: green[800], contrastText: '#fff' },
      protocol7: { main: teal[900], contrastText: '#fff' },
      protocol8: { main: green[900], contrastText: '#fff' },
    },
    typography: {
      fontFamily: 'Red Hat Text, sans-serif',
      h1: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 800,
        fontSize: '2.5rem',
        // lineHeight: '2.5rem',
        // paddingBlock: '.5em',
      },
      h2: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 600,
        fontSize: '2rem',
        // lineHeight: '1em',
      },
      h3: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 500,
        fontSize: '1.5rem',
        // lineHeight: '1em',
      },
      h4: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 500,
        fontSize: '1.25rem',
        // lineHeight: '1em',
      },
      h5: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 500,
        fontSize: '1rem',
        // lineHeight: '1em',
      },
      h6: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 800,
        fontSize: '.75rem',
        // lineHeight: '1em',
      },
      subtitle1: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
        // lineHeight: '1.5rem',
      },
      p: {
        fontFamily: 'Red Hat Text, sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
        // lineHeight: '1.5rem',
      },
      a: {
        fontFamily: 'Red Hat Text, sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
        // lineHeight: '1.5rem',
      },
      label: {
        fontFamily: 'Red Hat Text, sans-serif',
        fontWeight: 500,
        fontSize: '1rem',
        // lineHeight: '1rem',
      },
      specialConsiderationsTitle: {
        fontFamily: 'Red Hat Display, sans-serif',
        fontWeight: 500,
        fontSize: '1.25rem',
        // lineHeight: '1.75rem',
      },
      testing: {
        fontFamily: 'Times New Roman',
        fontSize: '12rem',
      },
      navbar: {
        fontFamily: 'Red Hat Display, sans-serif',
        // fontWeight: 800,
        fontSize: '1.4rem',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Red Hat Text';
            src: url(${RedHatText}) format('truetype');
            font-weight: normal;
            font-style: normal;
          }
          @font-face {
            font-family: 'Red Hat Text';
            src: url(${RedHatDisplay}) format('truetype');
            font-weight: normal;
            font-style: normal;
          }
  `,
        body: {
          fontFamily: 'Red Hat Text, sans-serif',
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: '1rem',
        },
      },
    },
  })
);
