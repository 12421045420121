import { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';

import { useQuery, useMutation } from '@apollo/client';
import {
  GET_FACILITIES,
  CREATE_ERROR,
  ADD_ROOM,
  UPDATE_ROOM,
  DELETE_ROOM,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_BRMS,
  UPDATE_ACTIVE,
} from './graphql';

import StandardWrapper from '../design/StandardWrapper';
import EditRoomForm from './EditRoomForm';
import EditBuildingForm from './EditBuildingForm';

const Facilities = ({ user }) => {
  /* ----------------------------------------- */
  /* STATE                                     */
  /* ----------------------------------------- */

  const [displayOptions, setDisplayOptions] = useState({ isActive: true, isBRMS: true });

  const [editRoomForm, setEditRoomForm] = useState(false);
  const [editBuildingForm, setEditBuildingForm] = useState(false);

  const [editedBuilding, setEditedBuilding] = useState({});
  const [editedRoom, setEditedRoom] = useState({});

  // Feedback
  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const userIsAdmin = user.groups.includes('admin');
  const userIsFacSupervisor = user.groups.includes('facilitySupervisor');

  /* ----------------------------------------- */
  /* API CALLS                                 */
  /* ----------------------------------------- */

  // Send Error
  const [createError] = useMutation(CREATE_ERROR);

  const onError = (e, action, data) => {
    createError({
      variables: {
        PVI: user.PVI,
        action,
        error: e ? JSON.stringify(e) : undefined,
        data: JSON.stringify({ ...data }),
      },
    });
    setErrMessage(
      e.message || 'Something went wrong. The BRMS IT office has received an error report and will contact you shortly.'
    );
  };

  // Get facilities data
  const {
    loading: facilitiesLoading,
    data: _facilitiesData,
    refetch: facilitiesRefetch,
  } = useQuery(GET_FACILITIES, {
    variables: {},
    onError: (e) => {
      onError(e, 'facilities', { user });
    },
  });
  const facilitiesData = _facilitiesData?.facilities || [];

  const [addRoom] = useMutation(ADD_ROOM, {
    onCompleted: () => {
      setSuccessMessage('Room Successfully Added!');
      facilitiesRefetch();
      setEditRoomForm(false);
      setEditedBuilding({});
      setEditedRoom({});
    },
    onError: (e) => {
      e.message === 'duplicate room'
        ? setErrMessage(
            `That room number already exists for this building. Please check the list for that room to see its details. If think you reached this message in error, please submit a help desk ticket.`
          )
        : onError(e, 'addRoom', { editedRoom, editedBuilding });
    },
  });

  const [updateRoom] = useMutation(UPDATE_ROOM, {
    onCompleted: () => {
      setSuccessMessage('Room Successfully Updated!');
      facilitiesRefetch();
      setEditRoomForm(false);
      setEditedBuilding({});
      setEditedRoom({});
    },
    onError: (e) => {
      onError(e, 'updateRoom', { editedRoom, editedBuilding });
    },
  });

  const [deleteRoom] = useMutation(DELETE_ROOM, {
    onCompleted: () => {
      setSuccessMessage('Room Successfully Deleted!');
      facilitiesRefetch();
      setEditRoomForm(false);
      setEditedBuilding({});
      setEditedRoom({});
    },
    onError: (e) => {
      onError(e, 'deleteRoom', { editedRoom, editedBuilding });
    },
  });

  const [addContact] = useMutation(ADD_CONTACT, {
    onCompleted: () => {
      setSuccessMessage('Contact Successfully Added!');
      facilitiesRefetch();
      setEditBuildingForm(false);
      setEditedBuilding({});
    },
    onError: (e) => {
      onError(e, 'addContact', { editedBuilding });
    },
  });

  const [removeContact] = useMutation(REMOVE_CONTACT, {
    onCompleted: () => {
      setSuccessMessage('Contact Successfully Removed!');
      facilitiesRefetch();
      setEditedBuilding({});
    },
    onError: (e) => {
      onError(e, 'removeContact', { editedBuilding });
    },
  });

  const [updateIsBRMS, { loading: brmsLoading }] = useMutation(UPDATE_BRMS, {
    onCompleted: () => {
      setSuccessMessage('Room Status Successfully Updated!');
      facilitiesRefetch();
      setEditedBuilding({});
    },
    onError: (e) => {
      onError(e, 'removeContact', { user });
    },
  });

  const [updateIsActive, { loading: activeLoading }] = useMutation(UPDATE_ACTIVE, {
    onCompleted: () => {
      setSuccessMessage('Room Status Successfully Updated!');
      facilitiesRefetch();
      setEditedBuilding({});
    },
    onError: (e) => {
      onError(e, 'removeContact', { user });
    },
  });

  /* ----------------------------------------- */
  /* HANDLERS                                  */
  /* ----------------------------------------- */

  // handle edit building data. Currently only adds contact
  const editBuilding = (building = {}) => {
    setEditedBuilding(building);
    setEditBuildingForm(true);
  };

  // handle edit room data
  const editRoom = (building, room = {}) => {
    setEditedBuilding(building);
    setEditedRoom(room);
    setEditRoomForm(true);
  };

  /* ----------------------------------------- */
  /* HELPERS                                   */
  /* ----------------------------------------- */

  // Shortens strings to certain length and appends ellipses
  const truncateString = (string, maxLength = 15) => {
    if (!string) return;
    if (string.length <= maxLength) return string;
    return string.slice(0, maxLength).concat('...');
  };

  const getLastUpdated = (roomArray) => {
    let result = Math.max(...roomArray.map((room) => new Date(room.updatedAt)));
    result = new Date(result).toLocaleString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
    return `Last updated: ${result}`;
  };

  /* ----------------------------------------- */
  /* RENDER                                    */
  /* ----------------------------------------- */

  return (
    <StandardWrapper xl>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Facilities</Typography>
        </Grid>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '.75em' }}>
          <Tooltip title="If you don't see a BRMS facility that should be listed here, contact a BRMS admin for assistance.">
            <HelpIcon sx={{ padding: '.25em' }} />
          </Tooltip>
        </Box>

        <Stack width="100%">
          {userIsAdmin && (
            <Accordion disableGutters elevation={6}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#dadfe1' }}>
                <Typography fontStyle="italic">Administrator Options</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container>
                  <Grid item md={8} xs={12}>
                    <Typography align="left" variant="body2">
                      By default, this list displays to all users only facilities that are active BRMS facilities. As a
                      user with administrator access, you can view other IACUC facilities in the UW system by using the
                      adjacent switches. You can change a facility's active and BRMS status by selecting it below and
                      toggling the appropriate checkmark in its listing.
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Stack>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '.75em', alignItems: 'center' }}>
                        <Typography variant="p">Show non-BRMS</Typography>
                        <Switch
                          size="small"
                          checked={!displayOptions.isBRMS}
                          onChange={() => setDisplayOptions({ ...displayOptions, isBRMS: !displayOptions.isBRMS })}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '.75em', alignItems: 'center' }}>
                        <Typography variant="p">Show inactive</Typography>
                        <Switch
                          size="small"
                          checked={!displayOptions.isActive}
                          onChange={() => setDisplayOptions({ ...displayOptions, isActive: !displayOptions.isActive })}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {facilitiesData
            .filter((building) => (displayOptions.isActive ? building.isActive : true))
            .filter((building) => (displayOptions.isBRMS ? building.isBRMS : true))
            .map((building) => (
              //////////////////////////////
              // FACILITY ACCORDION       //
              //////////////////////////////
              <Accordion key={building._id} disableGutters elevation={6}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#dadfe1' }}>
                  <Typography>{`${building.fpmCode} - ${building.name}`}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container>
                    <Grid container item lg={8} md={12} textAlign="left" padding="1em">
                      <Grid container item xs={12} md={8}>
                        <Grid item xs={12} md={4} justifyContent="flex-start">
                          <Typography variant="h6">Supervisor: </Typography>
                        </Grid>
                        <Grid item xs={12} md={8} justifyContent="flex-start">
                          {!!building.supervisor ? (
                            <Tooltip
                              title={<Typography variant="body1">{building.supervisor.emailAddress}</Typography>}
                              enterDelay={500}
                              leaveDelay={200}
                              enterTouchDelay={200}
                            >
                              <Typography variant="h6">
                                <span style={{ color: '#c5050c' }}>
                                  {building.supervisor.firstName} {building.supervisor.lastName}
                                </span>
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography variant="h6">BRMS supervisor not assigned</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4} justifyContent="flex-start">
                          <Typography variant="body1">Address:</Typography>
                        </Grid>
                        <Grid item xs={12} md={8} justifyContent="flex-start">
                          <Typography variant="body1">{building.address}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4} textAlign="right">
                        {userIsAdmin && (
                          <Stack padding=".5em">
                            <Box display="flex" alignItems="center" justifyContent="end">
                              <Typography variant="body2">BRMS Facility</Typography>

                              <Checkbox
                                sx={{ height: '1em' }}
                                size="small"
                                checked={building.isBRMS}
                                onChange={(e) => {
                                  updateIsBRMS({ variables: { buildingId: building._id, isBRMS: e.target.checked } });
                                }}
                              />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="end">
                              <Typography variant="body2">Active Facility</Typography>

                              <Checkbox
                                size="small"
                                sx={{ height: '1em' }}
                                checked={building.isActive}
                                onChange={(e) => {
                                  updateIsActive({
                                    variables: { buildingId: building._id, isActive: e.target.checked },
                                  });
                                }}
                              />
                            </Box>
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                    {/* 
                      ////////////////////////////// 
                      // CONTACTS ACCORDION // 
                      //////////////////////////////
                      */}
                    <Grid item lg={4} md={8} xs={12}>
                      <Accordion disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#dadfe1' }}>
                          <Typography>Contacts</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em', justifyContent: 'end' }}>
                            <List sx={{ maxHeight: 300, overflow: 'auto', width: '100%' }}>
                              {(userIsAdmin || userIsFacSupervisor) && (
                                <ListItem
                                  secondaryAction={
                                    <IconButton edge="end" onClick={() => editBuilding(building)}>
                                      <PersonAddAlt1Icon color="primary" fontSize="small" />
                                    </IconButton>
                                  }
                                >
                                  Add a contact
                                </ListItem>
                              )}

                              {building.contacts?.map((contact) => (
                                <Tooltip
                                  key={contact._id}
                                  title={
                                    <Typography variant="body1">
                                      {contact.emailAddress ? contact.emailAddress : ''}
                                    </Typography>
                                  }
                                  enterDelay={500}
                                  leaveDelay={200}
                                  enterTouchDelay={200}
                                >
                                  <ListItem
                                    secondaryAction={
                                      (userIsAdmin || userIsFacSupervisor) && (
                                        <IconButton
                                          edge="end"
                                          onClick={() =>
                                            removeContact({
                                              variables: { buildingId: building._id, userId: contact._id },
                                            })
                                          }
                                        >
                                          <PersonRemoveIcon color="primary" fontSize="small" />
                                        </IconButton>
                                      )
                                    }
                                  >
                                    <span style={{ color: '#c5050c' }}>
                                      {`${contact.firstName} ${contact.lastName}`}
                                    </span>
                                  </ListItem>
                                </Tooltip>
                              ))}
                            </List>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                  {/* 
                      ////////////////////////////// 
                      // ROOMS TABLE ///////////////
                      //////////////////////////////
                      */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '1em 1em .25em 1em ',
                      alignItems: 'end',
                      flexWrap: 'wrap',
                      gap: '.25em',
                    }}
                  >
                    <Typography variant="h6">Room List</Typography>
                    <Typography variant="subtitle1">Click on a room to interact</Typography>
                    <Typography variant="subtitle1">{getLastUpdated(building.rooms)}</Typography>
                    {(userIsAdmin || userIsFacSupervisor) && (
                      <Button onClick={() => editRoom(building)} variant="outlined" size="small" endIcon={<AddIcon />}>
                        Add Room
                      </Button>
                    )}
                  </Box>
                  <TableContainer
                    sx={{ maxHeight: '20em', border: '1px solid #dadfe1', borderRadius: '5px', cursor: 'pointer' }}
                  >
                    <Table size="small" sx={{ width: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Floor</TableCell>
                          <TableCell>Number</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Species</TableCell>
                          <TableCell>Resources</TableCell>
                          <TableCell>Hazards</TableCell>
                          <TableCell>Notes</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {building?.rooms
                          // sort by floor and room number
                          ?.slice()
                          .sort((a, b) => {
                            if (a.floor === b.floor) {
                              return a.number < b.number ? -1 : 1;
                            } else {
                              return a.floor < b.floor ? -1 : 1;
                            }
                          })
                          .map((room) => (
                            <TableRow key={room._id} hover onClick={() => editRoom(building, room)}>
                              <TableCell>{room.floor}</TableCell>
                              <TableCell>{room.number}</TableCell>
                              <TableCell>{room.type}</TableCell>
                              <TableCell sx={{ whiteSpace: 'pre-line' }}>
                                {room.species?.map((x) => x.name).join('\n')}
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'pre-line' }}>
                                {room.resources?.map((x) => x.resourceName).join('\n')}
                              </TableCell>
                              {room.hazards ? (
                                <Tooltip title={room.hazards}>
                                  <TableCell>{truncateString(room.hazards)}</TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell>{truncateString(room.hazards)}</TableCell>
                              )}
                              {room.notes ? (
                                <Tooltip title={room.notes}>
                                  <TableCell>{truncateString(room.notes)}</TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell>{truncateString(room.notes)}</TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
        </Stack>

        <Grid item xs={12}>
          <Box sx={{ height: '0.5em' }}>{facilitiesLoading && <LinearProgress />}</Box>
        </Grid>
      </Grid>

      {/* Building Edit Form */}
      <Dialog open={editBuildingForm} onClose={() => setEditBuildingForm(false)}>
        <EditBuildingForm
          building={editedBuilding}
          closeForm={() => setEditBuildingForm(false)}
          addContact={addContact}
        />
      </Dialog>

      {/* Room Edit Form */}
      <Dialog open={editRoomForm} onClose={() => setEditRoomForm(false)} fullWidth maxWidth="xs">
        <EditRoomForm
          room={editedRoom}
          building={editedBuilding}
          closeForm={() => setEditRoomForm(false)}
          addRoom={addRoom}
          updateRoom={updateRoom}
          deleteRoom={deleteRoom}
          userIsFac={userIsFacSupervisor}
          userIsAdmin={userIsAdmin}
        />
      </Dialog>

      {/* Success message */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" variant="filled" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Alert */}
      <Dialog open={!!errMessage} onClose={() => setErrMessage(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrMessage(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default Facilities;
