import React from 'react';
import { Container, Typography, Grid, List, ListItem, useMediaQuery } from '@mui/material';

const PrivacyNotice = () => (
  <a id="privacy-notice-link" target="_blank" href="https://www.wisc.edu/privacy-notice/" rel="noreferrer">
    Privacy
  </a>
);
const Copyright = () => (
  <p id="site-copyright">
    © 2021 Board of Regents of the{' '}
    <a id="site-footer-copyrightlink" target="_blank" href="https://www.wisconsin.edu" rel="noreferrer">
      University of Wisconsin System
    </a>
    &nbsp; &bull; &nbsp; <PrivacyNotice />
  </p>
);

const FooterLink = ({ url, children }) => (
  <a className="site-footer-link" target="_blank" href={url} rel="noreferrer">
    {' '}
    {children}{' '}
  </a>
);

const Footer = () => {
  const matches = useMediaQuery('(min-width:950px)');
  return (
    <footer id="site-footer">
      {matches ? (
        <Container>
          <Grid container>
            <Grid item xs>
              <Container maxWidth="xs">
                <a href="http://www.med.wisc.edu" aria-label="Link to main SMPH website">
                  <img width="250" height="150" src={process.env.PUBLIC_URL + '/SMPH_color-center-reverse.svg'} />
                </a>
              </Container>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">ABOUT</h3>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about">Biomedical Research Model Services</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://med.wisc.edu">School of Medicine and Public Health</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/smph-iacuc">SMPH IACUC</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://www.wisc.edu">University of Wisconsin - Madison</FooterLink>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">SERVICES</h3>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/facility-and-vivarium-management/">
                    Facility and Vivarium Management
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/animal-care-and-welfare/">
                    Animal Care and Welfare
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/procurement-and-logistics/">
                    Procurement and Logistics
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/research-services/">
                    Research Services
                  </FooterLink>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">CONTACT US</h3>
                </ListItem>
                <ListItem>
                  Medical Sciences Center <br />
                  1300 University Ave. <br />
                  Madison, WI 53706
                </ListItem>
                <ListItem>
                  Email: &nbsp; <FooterLink url="mailto:help@brms.wisc.edu">help@brms.wisc.edu</FooterLink>
                </ListItem>
                <ListItem>
                  Phone: &nbsp; <FooterLink url="tel:(608) 263-6466">(608) 263-6466</FooterLink>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs>
              <Container maxWidth="xs">
                <a href="http://www.med.wisc.edu" aria-label="Link to main SMPH website">
                  <img width="250" height="150" src={process.env.PUBLIC_URL + '/SMPH_color-center-reverse.svg'} />
                </a>
              </Container>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">ABOUT</h3>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about">Biomedical Research Model Services</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://med.wisc.edu">School of Medicine and Public Health</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/smph-iacuc">SMPH IACUC</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://www.wisc.edu">University of Wisconsin - Madison</FooterLink>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">SERVICES</h3>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/facility-and-vivarium-management/">
                    Facility and Vivarium Management
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/animal-care-and-welfare/">
                    Animal Care and Welfare
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/procurement-and-logistics/">
                    Procurement and Logistics
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink url="https://brms.wisc.edu/about/services/research-services/">
                    Research Services
                  </FooterLink>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs style={{ alignSelf: 'start', textAlign: 'start' }}>
              <List>
                <ListItem>
                  <h3 className="site-footer-subheader">CONTACT US</h3>
                </ListItem>
                <ListItem>
                  Medical Sciences Center <br />
                  1300 University Ave. <br />
                  Madison, WI 53706
                </ListItem>
                <ListItem>
                  Email: &nbsp; <FooterLink url="mailto:help@brms.wisc.edu">help@brms.wisc.edu</FooterLink>
                </ListItem>
                <ListItem>
                  Phone: &nbsp; <FooterLink url="tel:(608) 263-6466">(608) 263-6466</FooterLink>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      )}
      <br />
      <Container>
        <Copyright />
      </Container>
      <br />
    </footer>
  );
};

export default Footer;
