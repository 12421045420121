import React from 'react';

import moment from 'moment';

import {
  Table,
  LinearProgress,
  TextField,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNew from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from './design/StandardWrapper';

import { KeyboardArrowLeft } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Updated' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'link', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, canMutate } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {canMutate && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    onDelete,
    filterInput,
    onFilterInputChange,
    onAdd,
    allowDelete = true,
    allowAdd = false,
    allowEdit = false,
  } = props;

  return (
    <Toolbar>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          {numSelected > 0 && (
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
          )}
        </Grid>

        <Grid item xs={6} sm={5} md={4}>
          {numSelected > 0 ? (
            <span>
              {allowDelete && (
                <Tooltip title="Delete">
                  <span>
                    <IconButton aria-label="delete" onClick={onDelete} disabled={!allowDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {/* <Tooltip title="Edit Item">
                <span>
                  <IconButton aria-label="edit item" onClick={onEdit} disabled={!allowEdit}>
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip> */}
            </span>
          ) : (
            <div style={{ minWidth: 300 }}>
              <TextField
                value={filterInput}
                onChange={(e) => onFilterInputChange(e.target.value)}
                placeholder="Filter"
                size="small"
              />
              {allowAdd && (
                <Tooltip title="Add Item">
                  <IconButton aria-label="add sds" onClick={onAdd}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const GET_SDS_LINKS = gql`
  query SDSLinks {
    SDSLinks {
      _id
      name
      createdAt
      link
      description
    }
  }
`;

const DELETE_SDS_LINK = gql`
  mutation DeleteSDSLink($_id: ID, $_ids: [ID!]) {
    deleteSDSLink(_id: $_id, _ids: $_ids)
  }
`;

const processRows = ({ data, filterInput }) => {
  if (!data || !data.SDSLinks) {
    return [];
  }
  const { SDSLinks } = data;
  if (!Array.isArray(SDSLinks)) {
    console.error('Notification conditions on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'name', 'link', 'vendor', 'description'];
  let res = SDSLinks.map((x) => {
    const obj = {
      ...x,
    };
    if (!obj._id) {
      throw new Error(`Data object has no _id. Obj: ${JSON.stringify(x)}`);
    }
    obj.createdAt = moment(x.createdAt).unix();
    obj.name = x.name.toLowerCase();
    obj.nameLabel = x.name;
    obj.description = x.description.toLowerCase();
    obj.descriptionLabel = x.description;
    if (!(x.link.includes('http://') || x.link.includes('https://'))) {
      obj.link = `https://${x.link.trim()}`;
    }
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    return res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        }
      }
      return false;
    });
  }
  return res;
};

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const getTotalCount = (data) => {
  if (!data || !Array.isArray(data.inventoryItems)) {
    return 0;
  }
  return data.inventoryItems.length;
};

export default function InventoryItemsTable({ user, returnRoute }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formOpen, setFormOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [filterInput, setFilterInput] = React.useState('');
  console.log('selected: ', selected);

  const [createError] = useMutation(CREATE_ERROR);

  const { loading, error, data, refetch } = useQuery(GET_SDS_LINKS);

  const rows = processRows({ data, filterInput });

  const getSelectedItem = () => {
    if (selected.length !== 1) {
      return null;
    }
    const _id = selected[0];
    let item;
    rows.forEach((x) => {
      if (_id && x._id === _id) {
        item = { ...x };
      }
    });
    return item;
  };

  const [deleteSDSLink] = useMutation(DELETE_SDS_LINK, {
    onCompleted: () => {
      setSelected([]);
      refetch();
    },
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'deleteSDSLink',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            selected,
            rows,
          }),
        },
      });
    },
  });

  console.log('rows: ', rows);

  const handleRequestSort = (event, property) => {
    console.log('property: ', property);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    if (!user) return;

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = () => {
    deleteSDSLink({ variables: { _ids: selected } });
  };
  console.log('getSelectedItem: ', getSelectedItem());
  console.log(selected.length === 1);
  console.log(!!getSelectedItem());
  console.log(selected.length === 1 && !!getSelectedItem());
  const canMutate = !!user && user.groups && user.groups.some((x) => ['brmsSuperuser', 'admin'].includes(x));

  return loading ? (
    <StandardWrapper>
      <LinearProgress />
    </StandardWrapper>
  ) : (
    <StandardWrapper>
      <Typography variant="h1" id="tableTitle" component="div">
        Safety Data Sheets
      </Typography>

      <Hidden smDown>
        <Grid container>
          {user && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <IconButton
                  aria-label="back to item selection"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onDelete={handleDelete}
              onEdit={
                canMutate
                  ? () => {
                      setEditOpen(true);
                    }
                  : null
              }
              onAdd={
                canMutate
                  ? () => {
                      window.location.href = 'add-sds';
                    }
                  : null
              }
              filterInput={filterInput}
              onFilterInputChange={setFilterInput}
              allowEdit={canMutate && selected.length === 1 && !!getSelectedItem()}
              allowDelete={
                canMutate && (selected.length === 1 || (selected.length > 0 && selected.length < getTotalCount(data)))
              }
              allowAdd={canMutate}
              allowSelect={canMutate}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  canMutate={canMutate}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover={!!user}
                          onClick={(event) => handleClick(event, row._id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {canMutate && (
                              <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                            )}
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.nameLabel}
                          </TableCell>
                          <TableCell align="left">{moment.unix(row.createdAt).format('MM/DD/YYYY')}</TableCell>
                          <TableCell align="left">{row.descriptionLabel}</TableCell>
                          <TableCell align="left">
                            <a href={row.link} target="_blank" rel="noreferrer">
                              View SDS
                            </a>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Typography variant="h5">Safety Data Sheets</Typography>
        <Grid item xs={12}>
          <TextField
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            placeholder="Filter"
            variant="outlined"
            style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
          />
        </Grid>

        <List>
          {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <div>
                <ListItem key={row.link + row.name}>
                  <ListItemText
                    primary={row.nameLabel}
                    secondary={row.descriptionLabel ? row.descriptionLabel : null}
                  />
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      window.open(row.link, '_blank');
                    }}
                  >
                    <OpenInNew />
                  </IconButton>
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
        <br />
        <br />
      </Hidden>
    </StandardWrapper>
  );
}
