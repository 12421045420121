import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  OutlinedInput,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import StandardWrapper from '../design/StandardWrapper';
import { KeyboardArrowLeft } from '@mui/icons-material';
import _ from 'lodash';
import BuildingSearch from '../inputs/BuildingSearch';
import { buildingNameToCode } from '../../utils';

const AddResourceForm = ({ user, onSubmit = console.log }) => {
  const [resourceName, setResourceName] = useState('');
  const [building, setBuilding] = useState('');
  const [resourceType, setResourceType] = useState('');
  const [room, setRoom] = useState('');
  const [legacyCalendarEmail, setLegacyCalendarEmail] = useState('');

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setResourceName('');
    setBuilding('');
    setResourceType('');
    setRoom('');
    setLegacyCalendarEmail('');
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_RESOURCE = gql`
    mutation name(
      $resourceName: String!
      $resourceType: String!
      $building: String!
      $room: String!
      $legacyCalendarEmail: String
    ) {
      addResource(
        resourceName: $resourceName
        resourceType: $resourceType
        building: $building
        room: $room
        legacyCalendarEmail: $legacyCalendarEmail
      ) {
        _id
      }
    }
  `;

  const getVariables = () => {
    return {
      resourceName,
      building: buildingNameToCode(building) || building,
      resourceType,
      room,
      legacyCalendarEmail,
    };
  };

  const [createResource] = useMutation(CREATE_RESOURCE, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createResource',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('Resource Added!');
        resetForm();
        onSubmit(res.createResource);
      } else setToast();
    },
  });

  const validate = () => {
    const variables = getVariables();
    if (!variables.resourceType) {
      setValidationError('Please select a resource type');
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      const variables = getVariables();
      console.log('variables: ', variables);
      createResource({ variables });
    }
  };

  useEffect(() => {
    setResourceName(`${building} ${room}${room ? ' ' : ''}${resourceType}`);
  }, [resourceType, building, room]);

  return (
    <StandardWrapper>
      <Typography variant="h1">Add Resource Calendar</Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          handleSubmit();
        }}
      >
        <Grid container justifyContent="center" spacing={2} maxWidth="20em" margin="auto">
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Resource Type</InputLabel>
              <Select
                label="Resource Type"
                fullWidth
                value={resourceType}
                onChange={(e) => {
                  setResourceType(e.target.value);
                }}
                sx={{ textAlign: 'left' }}
              >
                <MenuItem value={'BSC'}>BSC</MenuItem>
                <MenuItem value={'Procedure Room'}>Procedure Room</MenuItem>
                <MenuItem value={'Containment'}>Containment</MenuItem>
                <MenuItem value={'Anesthesia'}>Anesthesia</MenuItem>
                <MenuItem value={'Animal Room'}>Animal Room</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <BuildingSearch
              key={key}
              val={building}
              sendInput={(x, value) => {
                setBuilding(value);
              }}
              label="Building"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              key={key}
              label="Room"
              value={room}
              onChange={(e) => {
                setRoom(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              key={key}
              disabled={!(resourceType && building && room)}
              label="Resource Name"
              value={resourceName}
              onChange={(e) => {
                setResourceName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              key={key}
              disabled={!(resourceType && building && room)}
              label="Legacy Calendar Email Address"
              value={legacyCalendarEmail}
              onChange={(e) => {
                setLegacyCalendarEmail(e.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <Button
              style={{ margin: 10, width: 300 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Add
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to add resource. The BRMS IT office has been alerted of this issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!validationError}
        onClose={() => {
          setValidationError();
        }}
      >
        <DialogTitle>{'Invalid Input'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidationError();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default AddResourceForm;
