import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import UserSearch from '../inputs/UserSearch';
import { sleep } from '../../utils';
import { gql } from '@apollo/client';

const CREATE_USER_MUTATION = gql`
  mutation UpdateUser($emailAddress: String!, $PVI: String!, $firstName: String!, $lastName: String!) {
    updateUser(emailAddress: $emailAddress, PVI: $PVI, firstName: $firstName, lastName: $lastName) {
      displayName
      PVI
      emailAddress
      firstName
      lastName
      displayName
    }
  }
`;

const GET_USERS = gql`
  {
    users {
      PVI
      firstName
      lastName
      emailAddress
      groups
    }
  }
`;

const UpdateUserForm = ({ user }) => {
  const [updateUser] = useMutation(CREATE_USER_MUTATION, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetState();
      } else setToast(false);
    },
  });

  const { loadingUsers, error, data, refetch } = useQuery(GET_USERS);

  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [PVI, setPVI] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const resetState = () => {
    setPVI('');
    setEmailAddress('');
    setFirstName('');
    setLastName('');
    setSelectedUser();
    refetch();
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const handleSelectUser = (user) => {
    const { PVI, emailAddress, firstName, lastName } = user;
    setPVI(PVI);
    setEmailAddress(emailAddress);
    setFirstName(firstName);
    setLastName(lastName);
    setSelectedUser(user);
  };

  return (
    <>
      {selectedUser ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            sleep(500);
            updateUser({
              variables: {
                PVI,
                firstName,
                lastName,
                emailAddress,
              },
            });
          }}
          onKeyPress={(event) => {
            if (event.which === 13 /* Enter */) {
              event.preventDefault();
            }
          }}
        >
          <Grid container justifyContent="space-between" spacing={2} maxWidth="80%" margin="auto">
            <Grid item xs={12}>
              <Typography variant="h2"> Update User </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                disabled
                variant="outlined"
                fullWidth
                label="PVI"
                placeholder="PVI"
                value={PVI}
                onChange={(e) => {
                  setPVI(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={!!selectedUser.emailAddress}
                variant="outlined"
                fullWidth
                label="Email Address"
                type="email"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required={!!selectedUser.firstName}
                variant="outlined"
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required={!!selectedUser.lastName}
                variant="outlined"
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Grid>

            <Grid item>
              <Button
                style={{ width: '10em' }}
                variant="outlined"
                // color="secondary"
                onClick={resetState}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                style={{ width: '10em' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !(PVI && emailAddress && firstName && lastName)}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Grid justifyContent="center">
          <Grid item xs={12}>
            <UserSearch
              sendInput={(name, value) => setPVI(value)}
              name="PVI"
              val={PVI}
              onSelectUser={handleSelectUser}
              loading={loadingUsers}
              error={error}
              data={data}
            />
          </Grid>
        </Grid>
      )}

      {/* Success */}
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          User has been created!
        </Alert>
      </Snackbar>

      {/* Non-specific error */}
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>User creation attempt failed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateUserForm;
