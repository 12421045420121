import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  Grid,
  TableSortLabel,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { useEffect, useState } from 'react';
import StandardWrapper from './design/StandardWrapper';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String) {
    notificationConditions(terms: $terms) {
      _id
      value
      user {
        PVI
        firstName
        lastName
        emailAddress
      }

      protocol {
        protocolNumber
        label
      }

      event {
        _id
        label
      }
    }
  }
`;

// const GET_EVENT_OPTIONS = gql`
//   query events($name: String) {
//     events(name: $name) {
//       _id
//       name
//       label
//     }
//   }
// `;

const GET_PROTOCOL_LABELS = gql`
  query protocolOptions($fragment: String) {
    protocolOptions(fragment: $fragment) {
      _id
      label
    }
  }
`;

const GET_USER_OPTIONS = gql`
  query userOptions($fragment: String) {
    userOptions(fragment: $fragment) {
      _id
      PVI
      firstName
      lastName
      emailAddress
    }
  }
`;

// Updates a single notification condition value based on NC's _id
// returns a custom object if that was the last NC subscribed in a protocol
const UPDATE_NOTIFICAITON_CONDITION = gql`
  mutation UpdateNotificationCondition($_id: ID!, $value: Boolean) {
    updateNotificationCondition(_id: $_id, value: $value) {
      ... on NotificationCondition {
        _id
        value
      }
      ... on RequiredNCNotReceivedError {
        lastActiveNC {
          user {
            firstName
            lastName
          }
          protocol {
            label
          }
        }
      }
    }
  }
`;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const NotificationAdmin = ({ user, user: { PVI } }) => {
  /* ----------------------------------------- */
  /* STATE                                     */
  /* ----------------------------------------- */

  const [protocolInput, setProtocolInput] = useState('');
  const [protocolOptions, setProtocolOptions] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState([]);

  const [userInput, setUserInput] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // const [eventOptions, setEventOptions] = useState([]);
  // const [selectedEvents, setSelectedEvents] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState('asc');

  // Customize table columns
  const headCells = [
    { id: 'protocol', label: 'Protocol' },
    { id: 'event', label: 'Event' },
    { id: 'user', label: 'User' },
    { id: 'email', label: 'Email' },
    { id: 'value', label: 'Active' },
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /* ----------------------------------------- */
  /* API CALLS                                 */
  /* ----------------------------------------- */

  // Send Error
  const [createError] = useMutation(CREATE_ERROR);

  // // Get Event Labels for Autocomplete
  // const { loading: eventLoading, data: eventData } = useQuery(GET_EVENT_OPTIONS, { variables: { name: 'submitSAR' } });

  // Get Protocol Labels for Autocomplete
  const [fetchProtocolOptions, { loading: protocolLoading, data: protocolData }] = useLazyQuery(GET_PROTOCOL_LABELS);

  // Get User Options for Autocomplete
  const [fetchUserOptions, { loading: userLoading, data: userData }] = useLazyQuery(GET_USER_OPTIONS);

  // Get notification conditions
  const [fetchNotificationConditions, { loading: ncLoading, data: ncData, refetch: ncRefetch }] =
    useLazyQuery(GET_NOTIFICATION_CONDITIONS);

  // Update notification condition state
  const [updateNotificationCondition] = useMutation(UPDATE_NOTIFICAITON_CONDITION, {
    onCompleted: ({ updateNotificationCondition: ncResponse }) => {
      // if last user was unsubscribed from one or more required NCs, notify user.
      if (ncResponse.__typename === 'RequiredNCNotReceivedError') {
        const { user, protocol } = ncResponse.lastActiveNC;

        // set dialog message and display
        setDialogMessage(
          `${user.firstName} ${user.lastName} was the last user receiving Sick and Dead Animal Report notifications for protocol(s): ${protocol.label}. To ensure these required notifications are being received, all users on the protocol have been resubscribed. Contact the protocol's owner with any questions.`
        );
        setDialogOpen(true);
      }

      ncRefetch();
    },
    // Error message
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateNotificationCondition',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
  });

  // Update protocol options list
  useEffect(() => {
    setProtocolOptions(protocolData?.protocolOptions || []);
  }, [protocolData]);

  // Update user options list
  useEffect(() => {
    setUserOptions(userData?.userOptions || []);
  }, [userData]);

  // Query NCs when autocompletes change
  useEffect(() => {
    // If search fields are empty, clear the Data Table
    if (selectedProtocols.length < 1 && selectedUsers.length < 1) {
      setTableData([]);
      return;
    }

    // Generate protocol search terms
    const protocolTerms =
      selectedProtocols.length > 0
        ? {
            protocol: { $in: selectedProtocols?.map((protocol) => protocol._id) },
          }
        : null;

    // Generate user search terms
    const userTerms = selectedUsers.length > 0 ? { user: { $in: selectedUsers?.map((user) => user._id) } } : null;

    // If neither search terms exist, cancel query
    if (protocolTerms === null && userTerms === null) {
      return;
    }

    // If either search term exists, add event search term
    // Currently this is only 'submitSAR' NCs
    const eventTerms =
      // eventData?.events?.length > 0 ? { event: { $in: eventData?.events.map((event) => event._id) } } : null;
      { autoGenerated: true };

    // Combine terms into one search object
    const search = { ...eventTerms, ...protocolTerms, ...userTerms };

    // Fetch
    fetchNotificationConditions({ variables: { terms: JSON.stringify(search) } });
  }, [selectedProtocols, selectedUsers]);

  // Update datatable when autocomplete selections change
  useEffect(() => {
    !!ncData &&
      setTableData(
        ncData?.notificationConditions?.map((row) => {
          return {
            _id: row._id,
            protocol: row?.protocol?.label || '',
            event: row?.event?.label || '',
            user: `${row.user?.lastName}, ${row.user?.firstName}` || '',
            email: row.user?.emailAddress || '',
            value: row.value,
          };
        })
      );
  }, [ncData]);

  /* ----------------------------------------- */
  /* HANDLERS                                  */
  /* ----------------------------------------- */

  // Handles data table checkbox click
  const handleUpdate = async (id, value) => {
    await updateNotificationCondition({ variables: { _id: id, value: !value } });
  };

  // Handle protocol input field change
  const handleProtocolInputChange = (event, value) => {
    // update input state
    setProtocolInput(value);

    // fetch labels
    if (value.length >= 2) {
      fetchProtocolOptions({
        variables: {
          fragment: value,
        },
      });
    }
  };

  // Handle protocol option selection
  const handleProtocolOnChange = (event, value) => {
    setProtocolOptions([...protocolData?.protocolOptions.filter((option) => !value.includes(option))]);
    setSelectedProtocols(value);
  };

  // Handle user input field change
  const handleUserInputChange = (event, value) => {
    // update input state
    setUserInput(value);

    // fetch users
    if (value?.length >= 2) {
      fetchUserOptions({
        variables: {
          fragment: value,
        },
      });
    }
  };

  // Handle user option selection
  const handleUserOnChange = (event, value) => {
    setUserOptions([...userData?.userOptions.filter((option) => !value.includes(option))]);
    setSelectedUsers([...value]);
  };

  // const handleEventOnChange = (event, newValue) => {
  //   setEventOptions(newValue ? [newValue, ...eventOptions] : eventOptions);
  //   setSelectedEvents([...newValue]);
  // };

  /* ----------------------------------------- */
  /* HELPERS                                   */
  /* ----------------------------------------- */

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  /* ----------------------------------------- */
  /* RENDER                                    */
  /* ----------------------------------------- */

  return (
    <StandardWrapper>
      <Typography variant="h1">Notification Administration</Typography>
      <Grid container spacing={2}>
        {/* Protocol Filter */}

        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            size="small"
            multiple
            value={selectedProtocols}
            onChange={handleProtocolOnChange}
            inputValue={protocolInput}
            onInputChange={handleProtocolInputChange}
            options={protocolOptions}
            getOptionLabel={(option) => option.label}
            renderTags={(value, props) =>
              value.map((option, index) => <Chip {...props({ index })} label={option.label} />)
            }
            renderInput={(params) => (
              <TextField {...params} label="Filter by Protocol" placeholder="Filter by Protocol" />
            )}
            loading={protocolLoading}
          />
        </Grid>

        {/* User Filter */}

        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            size="small"
            multiple
            value={selectedUsers}
            onChange={handleUserOnChange}
            inputValue={userInput}
            onInputChange={handleUserInputChange}
            options={userOptions}
            getOptionLabel={(option) => {
              return `${option?.lastName}, ${option?.firstName} - ${option?.emailAddress}`;
            }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip {...props({ index })} label={`${option?.lastName}, ${option?.firstName}`} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Filter by User" placeholder="Filter By User" />}
            loading={userLoading}
          />
        </Grid>

        {/* Event Filter */}

        {/* <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              size="small"
              value={selectedEvents}
              onChange={handleEventOnChange}
              options={eventData?.events || []}
              getOptionDisabled={(option) => true}
              getOptionLabel={(option) => option.label}
              renderTags={(value, props) =>
                value.map((option, index) => <Chip {...props({ index })} label={option.label} />)
              }
              renderInput={(params) => <TextField {...params} label="Filter by Event" placeholder="Filter By Event" />}
              loading={eventLoading}
            />
          </Grid> */}

        {/* Data Table */}
        <Grid item xs={12}>
          <Box sx={{ height: '0.5em' }}>{ncLoading && <LinearProgress />}</Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData.length < 1 && (
                  <TableRow>
                    <TableCell>
                      <h3>
                        {selectedProtocols.length < 1 && selectedUsers.length < 1
                          ? 'Enter search terms above'
                          : 'No notifications found for that protocol and/or user'}
                      </h3>
                    </TableCell>
                  </TableRow>
                )}
                {tableData?.sort(getComparator(order, orderBy)).map((nc) => (
                  <TableRow key={nc._id}>
                    <TableCell>{nc.protocol || 'Unlabeled Protocol'}</TableCell>
                    <TableCell>{nc.event || 'Sick and Dead Animal Reports'}</TableCell>
                    <TableCell>{nc.user}</TableCell>
                    <TableCell>{nc.email}</TableCell>
                    <TableCell>
                      <Checkbox checked={!!nc.value} onChange={() => handleUpdate(nc._id, nc.value)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <DialogActions>
            <Button variant="contained" size="small" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </StandardWrapper>
  );
};

export default NotificationAdmin;
