import { gql } from '@apollo/client';

import { CREATE_ERROR } from '../../global/graphql';

export { CREATE_ERROR };

export const LEGACY_DAR_QUERY = gql`
  query LegacyDARs(
    $emailAddress: String
    $onOrBefore: String
    $onOrAfter: String
    $building: String
    $protocolNumber: String
  ) {
    legacyDARs(
      emailAddress: $emailAddress
      onOrBefore: $onOrBefore
      onOrAfter: $onOrAfter
      building: $building
      protocolNumber: $protocolNumber
    ) {
      id
      dateSubmitted
      protocolNumber
      building
      room
      rack
      numDead
      numTotal
      comments
      recipients
      reportSubmitter
      species
      age
    }
  }
`;

export const LEGACY_SAR_QUERY = gql`
  query LegacySARs(
    $emailAddress: String
    $onOrBefore: String
    $onOrAfter: String
    $building: String
    $protocolNumber: String
  ) {
    legacySARs(
      emailAddress: $emailAddress
      onOrBefore: $onOrBefore
      onOrAfter: $onOrAfter
      building: $building
      protocolNumber: $protocolNumber
    ) {
      id
      dateSubmitted
      protocolNumber
      vetCardNo
      building
      room
      rack
      numSick
      numTotal
      observations
      description
      recipients
      reportSubmitter
      species
    }
  }
`;
