import React, { useState } from 'react';

import moment from 'moment';

import { buildingNameToCode } from '../../utils';

import ProtocolSearch from '../inputs/ProtocolSearch';
import BuildingSearch from '../inputs/BuildingSearch';

import { Button, Grid, TextField, FormControlLabel, Typography, Checkbox, Stack, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const LegacySARSearch = ({
  user,
  onSubmit,
  onClose,
  protocolNumber: _protocolNumber,
  building: _building,
  onOrAfter: _onOrAfter,
  onOrBefore: _onOrBefore,
  emailAddress: _emailAddress,
  title,
}) => {
  const [onOrBefore, setOnOrBefore] = useState(_onOrAfter);
  const [onOrAfter, setOnOrAfter] = useState(_onOrBefore);
  const [protocolNumber, setProtocolNumber] = useState(_protocolNumber);
  const [building, setBuilding] = useState(_building);
  const [emailAddress, setEmailAddress] = useState(_emailAddress);
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [useBuildingCode, setUseBuildingCode] = useState(true);

  // const resetState = () => {
  //   setOnOrBefore(null)
  //   setOnOrAfter(null)
  //   setProtocolNumber()
  //   setBuilding("")
  //   setEmailAddress("")
  //   setProtocolInput("")
  // }

  const handleProtocolCheck = (checked) => {
    setProtocolNumber('');
    setArrowProtocol(checked);
  };

  const handleBuildingCheck = (checked) => {
    setBuilding('');
    setUseBuildingCode(checked);
  };

  const handleDateChange = ({ name, dateString }) => {
    if (name === 'onOrAfter') {
      setOnOrAfter(dateString);
      if (onOrBefore && moment(dateString).unix() > moment(onOrBefore).unix()) {
        setOnOrBefore(dateString);
      }
    } else {
      setOnOrBefore(dateString);
      if (onOrAfter && moment(onOrAfter).unix() > moment(dateString).unix()) {
        setOnOrAfter(dateString);
      }
    }
  };

  return (
    <Stack maxWidth="min(30em, 100%)" margin="auto" spacing={1}>
      <Typography variant="h1">{title ? title : 'SAR Search Terms'}</Typography>

      {arrowProtocol ? (
        <ProtocolSearch
          name="protocol"
          required={false}
          onSelectProtocol={(x) => {
            if (x) {
              setProtocolNumber(x.protocolNumber);
            } else {
              setProtocolNumber(x);
            }
          }}
          val={protocolNumber}
          fullWidth
        />
      ) : (
        <TextField
          variant="outlined"
          label="Protocol"
          value={protocolNumber}
          onChange={(e) => {
            setProtocolNumber(e.target.value);
          }}
        />
      )}

      <FormControlLabel
        className="checkbox"
        control={
          <Checkbox
            checked={arrowProtocol}
            onChange={(e) => {
              handleProtocolCheck(e.target.checked);
            }}
          ></Checkbox>
        }
        label="ARROW Protocol"
      ></FormControlLabel>

      {/* <Grid item xs={9}>
        <TextField
          variant="outlined"
    
          label="Email Address"
          type="email"
          value={emailAddress}
          onChange={e => { setEmailAddress(e.target.value) }}
        />
      </Grid> */}

      <BuildingSearch
        val={building}
        sendInput={(x, value) => {
          console.log('value: ', value);

          // if (value && value.includes('(') && value.split('(').length > 1) {
          //   let str = value.split('(')[1]
          //   console.log("str: ", str)

          //   if (str.includes(')') && str.split(')').length > 0) {
          //     str = str.split(')')[0]
          //     console.log("str: ", str.split(')')[0])
          //     setBuilding(str)
          //   }
          // } else {
          //   setBuilding(value)
          // }
          if (useBuildingCode) {
            const code = buildingNameToCode(value);
            if (code) {
              setBuilding(code);
            } else {
              setBuilding(value);
            }
          } else {
            setBuilding(value);
          }
        }}
        freeSolo={!useBuildingCode}
        required={false}
      />

      <FormControlLabel
        className="checkbox"
        control={
          <Checkbox
            checked={useBuildingCode}
            onChange={(e) => {
              handleBuildingCheck(e.target.checked);
            }}
          ></Checkbox>
        }
        label="Use Building Code"
      ></FormControlLabel>

      <DatePicker
        value={onOrAfter || null}
        inputVariant="outlined"
        label="Beginning Date"
        format="MM/dd/yyyy"
        placeholder="Submitted On or After"
        onChange={(e) => {
          if (!e) return;
          else handleDateChange({ name: 'onOrAfter', dateString: moment(e).format() });
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />

      <DatePicker
        value={onOrBefore || null}
        inputVariant="outlined"
        label="End Date"
        format="MM/dd/yyyy"
        placeholder="Submitted On or Before"
        onChange={(e) => {
          if (!e) return;
          else handleDateChange({ name: 'onOrBefore', dateString: moment(e).format() });
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
      <Box display="flex" justifyContent="start" gap={1} />

      <Button
        variant="contained"
        type="submit"
        onClick={() => onSubmit({ protocolNumber, building, onOrAfter, onOrBefore, emailAddress })}
        sx={{ width: '10em' }}
      >
        Submit
      </Button>
      {onClose && (
        <Button variant="outlined" onClick={onClose} sx={{ width: '10em' }}>
          Cancel
        </Button>
      )}
    </Stack>
  );
};

export default LegacySARSearch;
