import { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material/';
import { useForm, FormContainer, SelectElement, TextFieldElement, AutocompleteElement } from 'react-hook-form-mui';

import { GET_ROOMS, GET_PROTOCOL_OPTIONS, SUBMIT_VCR, SUBMIT_AND_SEPARATE } from './graphql';

const VCTForm = ({ user, refetch, closeDialog, handleError }) => {
  // Get facilities data for 'Building - Room' options
  const { data: roomData } = useQuery(GET_ROOMS, {
    onError: (e) => {
      handleError(e, 'getRooms');
    },
  });

  // Get Protocol Labels for Autocomplete
  const [fetchProtocolOptions, { data: protocolData }] = useLazyQuery(GET_PROTOCOL_OPTIONS, {
    onError: (e) => {
      handleError(e, 'getProtocolOptions');
    },
  });

  const [submitVCR] = useMutation(SUBMIT_VCR, {
    onError: (e) => {
      handleError(e, 'submitVCR');
    },
  });

  const [submitAndSeparate] = useMutation(SUBMIT_AND_SEPARATE, {
    onError: (e) => {
      handleError(e, 'submitVCR');
    },
  });

  // get room from url param
  // const { roomId = '' } = useParams();

  const typeOptions = ['Overcrowded Cage', 'Singly-Housed Animal'];
  const reasonOptions = [
    'Double litter',
    'Litter was not weaned in a timely manner',
    'Too many adults',
    'Too many adults and a litter',
  ];

  const [protocolInput, setProtocolInput] = useState('');
  const [protocolValue, setProtocolValue] = useState('');
  const [protocolOptions, setProtocolOptions] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState(false);

  const formContext = useForm({
    defaultValues: {
      type: 'Overcrowded Cage', // TODO add overcrowded cages by making this a blank string
      buildingAndRoom: '',
      reason: '',
      protocol: '',
      rack: '',
      additionalDescription: '',
    },
  });

  const { watch, getValues, setValue, reset } = formContext;

  const watchType = watch('type');

  useEffect(() => {
    if (getValues('type') !== 'Overcrowded Cage') {
      setValue('reason', '');
    }
  }, [watchType]);

  // Update protocol options list
  useEffect(() => {
    setProtocolOptions(protocolData?.protocolOptions || []);
  }, [protocolData]);

  const isWeekend = () => {
    // TODO can remove this if statement
    if (process.env.NODE_ENV === 'development') return true;
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 is Sunday, 6 is Saturday
  };

  // HANDLERS

  // Handle protocol input field change
  const handleProtocolInputChange = (event, value) => {
    if (value.length > 20) return;
    // update input state
    setProtocolInput(value);

    // fetch labels
    if (value.length >= 2) {
      fetchProtocolOptions({
        variables: {
          fragment: value,
        },
      });
    }
  };

  const handleSubmit = () => {
    const input = {
      ...getValues(),
      protocol: getValues('protocol')._id,
      building: getValues('buildingAndRoom').building,
      room: getValues('buildingAndRoom').id,
      submittedBy: user._id,
    };
    delete input.buildingAndRoom;
    submitVCR({ variables: { input } });
    setConfirmDialog(false);
    reset();
    refetch();
    closeDialog();
  };

  const handleSubmitAndAdd = () => {
    const input = {
      ...getValues(),
      protocol: getValues('protocol')._id,
      building: getValues('buildingAndRoom').building,
      room: getValues('buildingAndRoom').id,
      submittedBy: user._id,
    };
    delete input.buildingAndRoom;
    submitVCR({ variables: { input } });
    setConfirmDialog(false);
    setValue('reason', '');
    setValue('rack', '');
    setValue('additionalDescription', '');

    refetch();
  };

  const handleSubmitAndSeparate = () => {
    const input = {
      ...getValues(),
      protocol: getValues('protocol')._id,
      building: getValues('buildingAndRoom').building,
      room: getValues('buildingAndRoom').id,
      submittedBy: user._id,
    };
    delete input.buildingAndRoom;
    submitAndSeparate({ variables: { input } });
    setConfirmDialog(false);
    setValue('reason', '');
    setValue('rack', '');
    setValue('additionalDescription', '');

    refetch();
    closeDialog();
  };

  return (
    <DialogContent>
      <FormContainer formContext={formContext} onSuccess={() => setConfirmDialog(true)}>
        <Stack spacing={2} padding="0.5em 0" width="min(80ch, 90%)" marginX="auto">
          <SelectElement
            label="Report type"
            name="type"
            options={typeOptions?.map((t) => ({ id: t, label: t }))}
            required
            fullWidth
          />
          {getValues('type') === 'Overcrowded Cage' && (
            <SelectElement
              label="Reason"
              name="reason"
              options={reasonOptions?.map((r) => ({ id: r, label: r }))}
              required={getValues('type') === 'Overcrowded Cage'}
            />
          )}

          <AutocompleteElement
            autocompleteProps={{
              onInputChange: handleProtocolInputChange,
              autoHighlight: true,
              ListboxProps: { style: { maxHeight: '15rem' } },
            }}
            AutocompleteProps={{}}
            label="Protocol"
            name="protocol"
            options={protocolOptions}
            required
          />

          <AutocompleteElement
            label="Building - Room"
            name="buildingAndRoom"
            autocompleteProps={{
              autoHighlight: true,
              ListboxProps: { style: { maxHeight: '15rem' } },
            }}
            options={
              roomData?.rooms.map((r) => ({
                id: r._id,
                label: `${r.building.name} - ${r.number}`,
                building: r.building._id,
              })) || []
            }
            required
          />

          <TextFieldElement label="Rack" name="rack" inputProps={{ maxLength: 20 }} />

          <TextFieldElement
            label="Additional Description"
            name="additionalDescription"
            inputProps={{ maxLength: 150 }}
            multiline
            minRows={3}
            maxRows={6}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save
            </Button>
          </Box>
        </Stack>
      </FormContainer>

      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle variant="title">Confirm Issue Report</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Reported At</TableCell>
                  <TableCell>{new Date().toLocaleString('en-US')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Reported By</TableCell>
                  <TableCell>
                    {user.firstName} {user.lastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                  <TableCell>{getValues('type')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Building</TableCell>
                  <TableCell>{getValues('buildingAndRoom')?.label?.split(' - ')[0]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Room</TableCell>
                  <TableCell>{getValues('buildingAndRoom')?.label?.split(' - ')[1]}</TableCell>
                </TableRow>
                {getValues('reason') && (
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Reason</TableCell>
                    <TableCell>{getValues('reason')}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Protocol</TableCell>
                  <TableCell>{getValues('protocol')?.label}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Rack ID</TableCell>
                  <TableCell>{getValues('rack')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Additional Description</TableCell>
                  <TableCell>{getValues('additionalDescription')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '.25em' }}>
              <Button variant="outlined" onClick={() => setConfirmDialog(false)}>
                Go Back
              </Button>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '.25em' }}>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
                <Button variant="contained" onClick={handleSubmitAndAdd}>
                  Submit and Add
                </Button>
              </Box>
            </Box>
            {isWeekend() && getValues('reason') === 'Double litter' && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.25em' }}>
                <Typography>Weekend option:</Typography>
                <Button variant="contained" onClick={handleSubmitAndSeparate}>
                  Submit and Separate
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </DialogContent>
  );
};

export default VCTForm;
