import React, { useState, useEffect } from 'react';

import { GET_USER_OPTIONS } from './graphql';
import { useLazyQuery } from '@apollo/client';

import { Autocomplete, Box, Button, Stack, Typography, TextField } from '@mui/material/';
// import { FormContainer, TextFieldElement, CheckboxElement } from 'react-hook-form-mui';

/* -------------------------------------------------------------------- */
/* RECOMMEND USING THIS COMPONENT WITH react-hook-form-mui IF PROVIDING */
/* USER INTERFACE TO EDIT BUILDING DATA                                 */
/* -------------------------------------------------------------------- */

export default function EditBuildingForm({ building, addContact, closeForm }) {
  /* ----------------------------------------- */
  /* STATE                                     */
  /* ----------------------------------------- */

  const [userOptions, setUserOptions] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [userValue, setUserValue] = useState(null);
  const [userError, setUserError] = useState(false);

  /* ---------------------------------------------- */
  /* BACKEND CALLS                                  */
  /* ---------------------------------------------- */

  // Get User Options for Autocomplete
  const [fetchUserOptions, { loading: userLoading, data: userData }] = useLazyQuery(GET_USER_OPTIONS);

  // Update user options list
  useEffect(() => {
    setUserOptions(userData?.userOptions || []);
  }, [userData]);

  // Handle user input field change
  const handleUserInputChange = (event, value) => {
    // update input state
    setUserInput(value);

    // fetch users
    if (value?.length >= 2) {
      fetchUserOptions({
        variables: {
          fragment: value,
        },
      });
    }
  };

  const handleAddContact = () => {
    if (!userValue) {
      setUserError(true);
      return;
    }
    addContact({ variables: { buildingId: building._id, userId: userValue._id } });
  };
  /* -------------------------------------------------- */
  /* COMPONENT RENDER                                   */
  /* -------------------------------------------------- */

  return (
    <Stack spacing={2} padding={2}>
      <Typography variant="h5">Add contact to {building.name}</Typography>
      <Autocomplete
        fullWidth
        size="small"
        value={userValue}
        onChange={(event, value) => setUserValue(value)}
        inputValue={userInput}
        onInputChange={handleUserInputChange}
        options={userOptions}
        getOptionLabel={(option) => {
          return `${option?.lastName}, ${option?.firstName} - ${option?.emailAddress}`;
        }}
        renderInput={(params) => {
          console.log({ params });
          return <TextField {...params} label="User Search" placeholder="User Search" error={userError} />;
        }}
        loading={userLoading}
      />

      <Box display="flex" justifyContent="space-between">
        <Button variant="outlined" onClick={closeForm}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAddContact}>
          Add
        </Button>
      </Box>
    </Stack>
  );
}
