import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  List,
  ListItem,
  DialogContentText,
  Divider,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemText,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Grid,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { KeyboardArrowLeft } from '@mui/icons-material';
import StandardWrapper from './design/StandardWrapper';
import { buildingCodeToName, buildingNameToCode, inventoryItemsMatch } from '../utils';
import { gql } from '@apollo/client';
import InventoryBuildingSearch from './inputs/InventoryBuildingSearch';

const formatNumber = (x) => {
  if (!x) return null;
  if (typeof x === 'number') return x;
  if (typeof x === 'string') return parseInt(x);
};

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const REMOVE_ITEM_FROM_CART = gql`
  mutation ($item: InputInventoryItem!) {
    removeInventoryItemFromCart(item: $item) {
      _id
    }
  }
`;

const UPDATE_CART = gql`
  mutation ($items: [InputCartItem]!) {
    updateInventoryItemQuantitiesInCart(items: $items) {
      _id
    }
  }
`;

const SUBMIT_CART = gql`
  mutation ($building: String!) {
    submitInventoryCart(building: $building) {
      _id
    }
  }
`;

export default function InventoryCart({ cart, refetch, user, onClose, onSubmit }) {
  const { items } = cart;

  const [updatedItems, setUpdatedItems] = React.useState([]);
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [building, setBuilding] = useState();
  const [key, setKey] = useState(true);

  const [createError] = useMutation(CREATE_ERROR);

  const [removeInventoryItemFromCart] = useMutation(REMOVE_ITEM_FROM_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'removeInventoryItemFromCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
      } else setToast(false);
    },
  });

  const [updateCart] = useMutation(UPDATE_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateCartQuantities',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            updatedItems,
            cart,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
      } else setToast(false);
    },
  });

  const [submitCart, { loading: updateCartLoading }] = useMutation(SUBMIT_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            updatedItems,
            cart,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
        onSubmit();
      } else setToast(false);
    },
  });

  const handleDelete = (item) => {
    let _item = { ...item };
    delete _item.__typename;
    removeInventoryItemFromCart({ variables: { item: _item } });
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
  };

  const handleChange = ({ item, quantity }) => {
    let updated = false;
    let _updatedItems = [...updatedItems];
    _updatedItems = _updatedItems.map((x) => {
      const { item: _item } = x;
      if (inventoryItemsMatch(item, _item)) {
        updated = true;
        return { item, quantity: formatNumber(quantity) };
      }
      return x;
    });
    if (!updated) {
      _updatedItems.push({ item, quantity: formatNumber(quantity) });
    }
    const res = _updatedItems.map((x) => {
      let _item = { ...x.item };
      delete _item.__typename;
      return {
        item: _item,
        quantity: x.quantity,
      };
    });
    setUpdatedItems(res);
    if (typeof formatNumber(quantity) === 'number' && quantity > 0 && quantity < 1000000000) {
      handleUpdate(res);
    }
  };

  const handleUpdate = (res) => {
    updateCart({
      variables: {
        items: res.map((x) => {
          if (!x.quantity) return { ...x, quantity: 0 };
          return x;
        }),
      },
    });
  };

  const handleSubmit = () => {
    console.log('building: ', building);

    submitCart({ variables: { building: buildingNameToCode(building) || building } });
  };

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h1">Your Shopping Cart</Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="back to item selection"
            onClick={() => {
              window.location.href = '/order-inventory';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // sleep(500);
          handleSubmit();
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            {items.length > 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(({ item, quantity }, index) => {
                    let _quantity;
                    let quantitySet = false;
                    updatedItems.forEach((x) => {
                      if (inventoryItemsMatch(item, x.item)) {
                        console.log('x: ', x);
                        _quantity = x.quantity;
                        quantitySet = true;
                      }
                    });
                    if (!quantitySet) {
                      _quantity = quantity;
                    }
                    console.log('_quantity: ', _quantity);

                    const { name, unit, description } = item;
                    return (
                      <TableRow key={index}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{description}</TableCell>

                        <TableCell>
                          <TextField
                            variant="outlined"
                            required
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            inputProps={{
                              min: 1,
                              max: 1000000000,
                            }}
                            value={_quantity}
                            onChange={(e) => {
                              handleChange({ item, quantity: e.target.value });
                            }}
                            size="small"
                          />
                        </TableCell>

                        <TableCell>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              handleDelete(item);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h5">You Have No Items In Your Cart</Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <InventoryBuildingSearch
              key={key}
              val={building}
              sendInput={(x, value) => {
                setBuilding(value);
              }}
              label="Building"
              required
              fullWidth
            />
          </Grid>
          <Grid item sx={12} sm={6} textAlign="right">
            <Button
              style={{ width: '10em' }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !building || items.length < 1 || updateCartLoading}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Failed to update item</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
}
