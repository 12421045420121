export const AxisLeft = ({ yScale }) => {
  const protocolWidth = 25;
  return (
    <>
      {yScale.domain().map((tick, i) => (
        <g transform={`translate(0, ${yScale(tick) + yScale.bandwidth() / 2})`} key={i}>
          <text style={{ textAnchor: 'end', dominantBaseline: 'middle', maxWidth: '12em' }} x={0} dx="-1em">
            {tick.length > protocolWidth ? tick.slice(0, protocolWidth - 3) + '...' : tick}
          </text>
        </g>
      ))}
    </>
  );
};
