import { Alert, Collapse, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SiteAlert = ({ open, setOpen }) => {
  return (
    <Collapse in={open} sx={{ width: 'min(80ch, 90%)', margin: '.5em auto' }}>
      <Alert
        variant="outlined"
        severity="info"
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        }
      >
        {/* <AlertTitle>Site Update</AlertTitle> */}
        This BRMS website has recently undergone a wide-scale update to remain in compliance with UW standards. If any
        web tools are not behaving as expected, please contact BRMS IT staff immediately by{' '}
        <Link href="https://uwsmph.teamwork.com/support/#/tickets/new">submitting a help desk ticket</Link>.
      </Alert>
    </Collapse>
  );
};

export default SiteAlert;
