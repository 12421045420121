import moment from 'moment';
import { csvFormat } from 'd3-dsv';

export const getBuildings = (data) => {
  return data.reduce((acc, vcr) => {
    if (acc.findIndex((obj) => obj.name === vcr.buildingName) === -1) {
      acc.push({ name: vcr.buildingName, checked: true });
    }
    return acc;
  }, []);
};
export const getSdarBuildings = (data) => {
  return data.reduce((acc, dar) => {
    const buildingString = dar.building;
    // const buildingString = dar.building.split(', ')[0];
    if (acc.findIndex((obj) => obj.name === buildingString) === -1) {
      acc.push({ name: buildingString, checked: true });
    }
    return acc;
  }, []);
};

// Distill reports into counts per protocol
export const reportsPerProtocol = (arr) => {
  // Create an object with the counts
  const reduction = arr.reduce((acc, obj) => {
    acc[obj.protocolLabel] = (acc[obj.protocolLabel] || 0) + 1;
    return acc;
  }, {});

  // Transform the counts object into an array
  const result = Object.keys(reduction).map((protocolLabel) => ({ protocolLabel, count: reduction[protocolLabel] }));
  result.sort((a, b) => b.count - a.count);
  return result;
};

/* Takes data from vcr call and applies a status label to be used by this graph. */
const getStatus = (vcr) => {
  if (!!vcr.resolvedAt) {
    if (!!vcr.staffResolutionRequestedAt) {
      return 'Resolved by BRMS staff';
    }

    if (moment(vcr.resolvedAt) >= moment(vcr.deadline)) {
      return 'Resolved overdue';
    }
    return 'Resolved on time';
  }
  if (!vcr.resolvedAt) {
    return 'Unresolved';
  }
};

export const tallyStatuses = (data) =>
  data.reduce((acc, vcr) => {
    const status = getStatus(vcr);
    const index = acc.findIndex((obj) => obj.status === status);
    if (index === -1) {
      acc.push({ status, count: 1 });
    } else {
      acc[index].count = acc[index].count + 1;
    }

    return acc;
  }, []);

export const ocrReasons = (data) =>
  data.reduce((acc, vcr) => {
    if (vcr.type !== 'Overcrowded Cage') return acc;
    const index = acc.findIndex((obj) => obj.reason.toLowerCase() === vcr.reason.toLowerCase());
    if (index === -1) {
      acc.push({ reason: vcr.reason, count: 1 });
    } else {
      acc[index].count = acc[index].count + 1;
    }

    return acc;
  }, []);

/* Sorts array of vrc data into buckets of statuses by protocol. */
export const countStatusesPerProtocol = (arr) => {
  const result = arr.reduce((acc, vcr) => {
    if (acc.findIndex((obj) => obj.protocolLabel === vcr.protocolLabel) === -1) {
      acc.push({ protocolLabel: vcr.protocolLabel, status: 'Resolved on time', count: 0 });
      acc.push({ protocolLabel: vcr.protocolLabel, status: 'Resolved by BRMS staff', count: 0 });
      acc.push({ protocolLabel: vcr.protocolLabel, status: 'Resolved overdue', count: 0 });
      acc.push({ protocolLabel: vcr.protocolLabel, status: 'Unresolved', count: 0 });
    }

    const status = getStatus(vcr);

    const index = acc.findIndex((obj) => obj.protocolLabel === vcr.protocolLabel && obj.status === status);

    if (index == -1) {
      console.log('Data needs to be checked for errors.');
      return acc;
    } else {
      acc[index].count++;
    }

    return acc;
  }, []);

  return result;
};

// Export Data to CSV
export const exportCSV = (view, data, dateFrom, dateTo) => {
  const csvData = csvFormat(data);

  // Create a Blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a hidden link and attach the URL to it
  const link = document.createElement('a');
  link.href = url;
  const filename = `${view}${dateFrom.format('MMDDYY')}-${dateTo.format('MMDDYY')}.csv`;
  link.download = filename;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Clean up by removing the link
  document.body.removeChild(link);
};

export const dimensionDefaults = () => {
  const margin = { top: 20, right: 20, bottom: 20, left: 20 };
  const yAxisWidth = 190;
  const xAxisHeight = 10;
  const titleOffset = 25;
  const xAxisLabelOffset = 30;
  const innerHeight = 600;
  const legendOffset = 20;
  const height = innerHeight + margin.top + margin.bottom + xAxisHeight + xAxisLabelOffset + legendOffset;
  const width = Math.min(window.innerWidth, 500);
  const yAxisLabelOffset = 20;
  const innerWidth = width - margin.left - margin.right - yAxisWidth - yAxisLabelOffset;

  return {
    width,
    height,
    margin,
    titleOffset,
    yAxisWidth,
    yAxisLabelOffset,
    xAxisHeight,
    xAxisLabelOffset,
    innerHeight,
    innerWidth,
    legendOffset,
  };
};
