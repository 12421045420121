import React, { useState } from 'react';
import { FormControlLabel, TextField, Checkbox, Grid, Hidden } from '@mui/material';

const HazardousSubstanceGroup = ({ sendInput, val, active, requireDescription }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          style={{ marginBottom: 10 }}
          control={
            <Checkbox
              id="hazard-box"
              checked={val.hazardExposure || false}
              onChange={(e) => {
                setIsChecked(!isChecked);
                sendInput('hazardExposure', e.target.checked);
              }}
            ></Checkbox>
          }
          label="Exposed to Hazardous Substances"
        />
      </Grid>

      {(isChecked || active) && (
        <Grid item xs={12}>
          <TextField
            required={requireDescription}
            variant="outlined"
            label={isChecked || active ? 'Hazard Description' : ''}
            placeholder="Describe details of expected hazards"
            multiline={isChecked || active}
            fullWidth
            minRows={3}
            maxRows={6}
            value={val.biohazardAgent || null}
            onChange={(e) => {
              sendInput('biohazardAgent', e.target.value);
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default HazardousSubstanceGroup;
