import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import AnimalTable from './AnimalTable';
import FormDivider from '../design/FormDivider';
import USDAList from '../../USDAList.json';

export const isUSDAAnimal = (animal) => {
  animal = animal.toLowerCase();

  const USDAExceptionList = [
    'rat',
    'rats',
    'mouse',
    'mice',
    'hamster',
    'hamsters',
    'gerbil',
    'gerbils',
    'turtle',
    'turtles',
    'snake',
    'snakes',
    'frog',
    'frogs',
    'toad',
    'toads',
    'fish',
    'chicken',
    'turkey',
  ];

  const USDAAnimalList = [
    'guinea pig',
    'hedgehog',
    'chinchilla',
    'dog',
    'dogs',
    'cat',
    'cats',
    'ferret',
    'ferrets',
    'pigs',
    'rabbit',
    'rabbits',
    'donkey',
    'goats',
    'bat',
    'bear',
    'cheetah',
    'fox',
    'boar',
    'hare',
    'deer',
    'ram',
    'squirrel',
    'chipmunk',
    'macaque',
    'USDA-Covered Mice',
    'USDA-Covered Rats',
  ];

  const animalObject = USDAList[animal];

  if (animalObject !== undefined) {
    return animalObject.USDA === 'x' ? { found: true, isUSDA: true } : { found: true, isUSDA: false };
  }

  if (USDAAnimalList.includes(animal)) return { found: true, isUSDA: true };
  if (USDAExceptionList.includes(animal)) return { found: true, isUSDA: false };

  return { found: false, isUSDA: true };
};

const TableSwitch = ({ species, sendInput, children, allRequired, val, USDAStatus, setUSDAStatus }) => {
  useEffect(() => {
    setUSDAStatus(isUSDAAnimal(species));
  }, [species]);
  if (USDAStatus.isUSDA) {
    return (
      <>
        <Grid item xs={12} textAlign="left">
          <FormDivider text="Animals" />
        </Grid>
        <Grid item xs={12} textAlign="left">
          <AnimalTable sendInput={sendInput} val={val} allRequired={allRequired} />
        </Grid>
      </>
    );
  }

  return <>{children}</>;
};

export default TableSwitch;
