import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  IconButton,
  Checkbox,
  Select,
  MenuItem,
  Hidden,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import ConditionalClearInput from '../inputs/ConditionalClearInput';
import StandardWrapper from '../design/StandardWrapper';
import ProtocolSearch from '../inputs/ProtocolSearch';
import FastBuildingSearch from '../inputs/FastBuildingSearch';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import { buildingNameToCode } from '../../utils';
import { KeyboardArrowLeft } from '@mui/icons-material';

import FormDivider from '../design/FormDivider';

const procedures = [
  'Blood draw/labs',
  'Catheterization',
  'Device check',
  'Imaging',
  'Myocardial infarction',
  'Surgery',
  'Training Lab',
  "Other (Describe in Add'l Details)",
];

const appendTime = ({ date, time }) => {
  const timeMoment = moment(time);
  const hour = timeMoment.hour();
  const minute = timeMoment.minute();
  return moment(date).hour(hour).minute(minute).second(0).format();
};

const FastRequestForm = ({ user }) => {
  const [protocolNumber, setProtocolNumber] = useState();
  const [building, setBuilding] = useState();
  const [animalId, setAnimalId] = useState();
  const [procedureDate, setProcedureDate] = useState(null);
  const [procedureTime, setProcedureTime] = useState(null);
  const [fastStart, setFastStart] = useState(null);
  const [fastStartTime, setFastStartTime] = useState(null);
  const [fastEnd, setFastEnd] = useState(null);
  const [fastEndTime, setFastEndTime] = useState(null);
  const [terminal, setTerminal] = useState(false);
  const [procedure, setProcedure] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState();
  const [durationWarning, setDurationWarning] = useState();
  const [requireVetContact, setRequireVetContact] = useState(false);
  const [vetContacted, setVetContacted] = useState(false);
  const [save, setSave] = useState(true);

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [bypassOpen, setBypassOpen] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const resetForm = () => {
    if (!save) {
      setProtocolNumber();
      setBuilding();
      setAnimalId();
      setProcedureDate(null);
      setProcedureTime(null);
      setFastStart(null);
      setFastStartTime(null);
      setFastEnd(null);
      setFastEndTime(null);
      setTerminal(false);
      setProcedure('');
      setAdditionalDetails();
      setKey(!key);
    }
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_FAST_REQUEST = gql`
    mutation name(
      $protocolNumber: String!
      $building: String!
      $animalId: String!
      $procedureDate: String!
      $fastStart: String!
      $fastEnd: String
      $terminal: Boolean!
      $procedure: String!
      $additionalDetails: String
    ) {
      createFastRequest(
        protocolNumber: $protocolNumber
        building: $building
        animalId: $animalId
        procedureDate: $procedureDate
        fastStart: $fastStart
        fastEnd: $fastEnd
        terminal: $terminal
        procedure: $procedure
        additionalDetails: $additionalDetails
      ) {
        _id
      }
    }
  `;

  // Submissions must be placed by 12:30 the day before the fast
  const isOnTime = ({ fastStartMoment, tomorrowMoment }) => {
    // cant request before tomorrow
    if (fastStartMoment.unix() < tomorrowMoment.unix()) {
      return false;
    }
    // it only matters if the fast starts tomorrow
    if (
      fastStartMoment.dayOfYear() === tomorrowMoment.dayOfYear() &&
      fastStartMoment.year() === tomorrowMoment.year()
    ) {
      // if the fast is tomorrow and it is currently past 12:30 return false
      const currentMoment = moment().tz('America/Chicago');
      if (currentMoment.hour() > 12 || (currentMoment.hour() === 12 && currentMoment.minute() > 30)) {
        return false;
      }
    }
    return true;
  };

  const validate = (args) => {
    const bypassOnTime = args && args.bypassOnTime;
    const variables = getVariables();
    if (!moment(procedureDate).isValid()) {
      setValidationError('Invalid Procedure Date');
      return false;
    }
    if (!moment(procedureTime).isValid()) {
      setValidationError('Invalid Procedure Time');
      return false;
    }
    if (!moment(fastStart).isValid()) {
      setValidationError('Invalid Fast Start Date');
      return false;
    }
    if (!moment(fastStartTime).isValid()) {
      setValidationError('Invalid Fast Start Time');
      return false;
    }
    if (!terminal) {
      if (!moment(fastEnd).isValid()) {
        setValidationError('Invalid Fast End Date');
        return false;
      }
      if (!moment(fastEndTime).isValid()) {
        setValidationError('Invalid Fast End Time');
        return false;
      }
    }
    const procedureUnix = moment(variables.procedureDate).unix();
    const fastStartUnix = moment(variables.fastStart).unix();
    const procedureMoment = moment(variables.procedureDate).tz('America/Chicago');
    const fastStartMoment = moment(variables.fastStart).tz('America/Chicago');
    const fastEndMoment = moment(variables.fastEnd).tz('America/Chicago');
    const tomorrowMoment = moment().tz('America/Chicago').add(1, 'day').hour(0).minute(0).second(0);
    if (procedureUnix < fastStartUnix) {
      setValidationError('The procedure cannot be before the fast start.');
      return false;
    }
    if (!variables.terminal) {
      const fastEndUnix = moment(variables.fastEnd).unix();
      if (fastEndUnix < fastStartUnix) {
        setValidationError('The fast end cannot be before the fast start.');
        return false;
      }
      if (fastEndUnix < procedureUnix) {
        setValidationError('The fast end cannot be before the procedure.');
        return false;
      }
    }

    if (bypassOnTime) {
      return true;
    }
    if (!isOnTime({ tomorrowMoment, fastStartMoment })) {
      if (['admin', 'brmsSuperuser'].some((group) => user.groups.includes(group))) {
        // TODO special admin bypass
        setBypassOpen(true);
        return false;
      } else {
        setValidationError(
          'The deadline for fast requests is 12:30 pm America/Chicago the day before the fast is to begin. This request cannot be submitted with the current fast start date. If this is urgent please contact the area supervisor directly.'
        );
        return false;
      }
    }

    let duration;
    if (terminal) {
      duration = moment.duration(procedureMoment.diff(fastStartMoment));
    } else {
      duration = moment.duration(fastEndMoment.diff(fastStartMoment));
    }
    const hours = duration.asHours();
    if (hours < 6 && !durationWarning) {
      setDurationWarning(
        `It is recommended animals are fasted for a minimum of 6 hours and a maximum of 12 hours. The selected fasting window is shorter than the recommended minimum.`
      );
      return false;
    }
    if (hours >= 20 && !vetContacted) {
      setRequireVetContact(true);
      setDurationWarning(
        'The selected fasting window is longer than the recommended maximum. Contact an RARC veterinarian assigned to the SMPH.'
      );
      return false;
    }
    // if (hours > 12 && !durationWarning) {
    //     setDurationWarning("It is recommended animals are fasted for a minimum of 6 hours and a maximum of 12 hours. The selected fasting window is longer than the recommended maximum.")
    //     return false
    // }
    return true;
  };

  const bypass = () => {
    if (validate({ bypassOnTime: true })) {
      setBypassOpen(false);
      setLoading(true);
      handleSubmit();
    }
  };

  const getVariables = () => {
    return {
      protocolNumber,
      building: buildingNameToCode(building) || building,
      animalId,
      procedureDate: appendTime({ date: procedureDate, time: procedureTime }),
      fastStart: appendTime({ date: fastStart, time: fastStartTime }),
      fastEnd: !terminal ? appendTime({ date: fastEnd, time: fastEndTime }) : undefined,
      terminal,
      procedure,
      additionalDetails,
    };
  };

  const [createFastRequest] = useMutation(CREATE_FAST_REQUEST, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createFastRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createFastRequest({ variables });
  };

  const handleSelectProtocol = (protocol) => {
    protocol ? setProtocolNumber(protocol.protocolNumber) : setProtocolNumber();
  };

  const handleDurationSubmit = () => {
    if (validate()) {
      setLoading(true);
      handleSubmit();
      setVetContacted(false);
      setRequireVetContact(false);
      setDurationWarning();
    }
  };

  // const handleSelectTime = ({type, time}) => {
  //     console.log("type: ", type)
  //     console.log("time: ", time)
  //     let setDateFunction;
  //     let setTimeFunction;
  //     let date;
  //     switch(type) {
  //         case "procedure":
  //             date = moment(procedureDate)
  //             setDateFunction = setProcedureDate
  //             setTimeFunction = setProcedureTime
  //             break;
  //         case "startFast":
  //             date = moment(fastStart)
  //             setDateFunction = setFastStart
  //             setTimeFunction = setFastStartTime
  //             break;
  //         case "endFast":
  //             date = moment(fastEnd)
  //             setDateFunction = setFastEnd
  //             setTimeFunction = setFastEndTime
  //             break;
  //         default: throw new Error(`Invalid time type ${type}`)
  //     }
  //     setTimeFunction

  // }
  return (
    <StandardWrapper>
      <Typography variant="h1">New Fasting Request</Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (validate()) {
            setLoading(true);
            handleSubmit();
          }
        }}
      >
        <Grid container justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12} textAlign="left">
            <FormDivider />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProtocolSearch
              fullWidth
              name="protocol"
              required
              key={key}
              onSelectProtocol={handleSelectProtocol}
              val={protocolNumber}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FastBuildingSearch
              key={key}
              val={building}
              sendInput={(x, value) => {
                setBuilding(value);
              }}
              label="Building"
              required
            />
          </Grid>

          <Grid item xs={12} md={6} textAlign="left">
            <FormControlLabel
              className="checkbox"
              style={{ width: 300, margin: 10 }}
              control={
                <Checkbox
                  key={key}
                  checked={terminal}
                  onChange={(e) => {
                    setTerminal(e.target.checked);
                    e.target.checked ? setProcedure('Terminal Procedure') : setProcedure('');
                  }}
                ></Checkbox>
              }
              label="Terminal Procedure"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl required label="Procedure" fullWidth>
              <Select
                labelId="procedure-select-label"
                id="procedure-select"
                value={procedure}
                defaultValue={procedure}
                onChange={(e) => {
                  setProcedure(e.target.value);
                }}
                displayEmpty
                style={{ textAlign: 'left' }}
                variant="outlined"
                disabled={terminal}
              >
                {!procedure && (
                  <MenuItem value="">
                    <span style={{ color: '#666', fontWeight: 300 }}>Procedure*</span>
                  </MenuItem>
                )}
                {procedures.map((x, i) => (
                  <MenuItem key={i} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              required
              key={key}
              label="Animal ID"
              value={animalId}
              fullWidth
              onChange={(e) => {
                setAnimalId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6}>
            <DatePicker
              label="Procedure Date"
              value={procedureDate}
              onChange={setProcedureDate}
              disablePast
              renderInput={(params) => <TextField fullWidth required {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="Procedure Time"
              value={procedureTime}
              onChange={setProcedureTime}
              renderInput={(params) => <TextField fullWidth required {...params} />}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              label="Fast Start Date"
              disablePast
              value={fastStart}
              onChange={setFastStart}
              renderInput={(params) => <TextField fullWidth required {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="Fast Start time"
              value={fastStartTime}
              onChange={setFastStartTime}
              renderInput={(params) => <TextField fullWidth required {...params} />}
            />
          </Grid>
          {!terminal && (
            <>
              <Grid item xs={12} md={6}>
                <DatePicker
                  disablePast
                  value={fastEnd}
                  label="Fast End Date"
                  onChange={setFastEnd}
                  renderInput={(params) => <TextField fullWidth required {...params} />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  label="Fast End Time"
                  value={fastEndTime}
                  onChange={setFastEndTime}
                  renderInput={(params) => <TextField fullWidth required {...params} />}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              key={key}
              required={procedure === "Other (Describe in Add'l Details)"}
              label="Additional Details"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12} textAlign="left">
            <ConditionalClearInput section="all" effect={setSave} label="Save form after submission" />
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={12} textAlign="right">
            <Button
              style={{ width: 125, margin: 10 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Fasting Request Submitted!
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to submit fasting request. The BRMS IT office has been alerted of this issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!validationError}
        onClose={() => {
          setValidationError();
        }}
      >
        <DialogTitle>{'Invalid Input'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidationError();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!bypassOpen}>
        <DialogTitle>{'Late Submission Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are attempting to submit a fasting request after the deadline. The deadline for fasting requests is
            12:30 pm America/Chicago the day before the fast is to begin. As a{' '}
            {user.groups.includes('admin') ? 'website administrator' : 'BRMS superuser'} you are authorized to bypass
            this restriction. By doing so, you agree to take responsibitity for ensuring that the animal is fasted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setBypassOpen(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={bypass} color="primary">
            Agree and Bypass
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!durationWarning}
        onClose={() => {
          setDurationWarning();
          setVetContacted(false);
          setRequireVetContact(false);
        }}
      >
        <DialogTitle>Please Review Fasting Duration</DialogTitle>
        <DialogContent>
          <DialogContentText>{durationWarning}</DialogContentText>
          {requireVetContact && (
            <div>
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    key={key}
                    checked={vetContacted}
                    onChange={(e) => {
                      setVetContacted(e.target.checked);
                    }}
                  ></Checkbox>
                }
                label="I have contacted an RARC veterinarian about this fasting window"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setDurationWarning();
                setVetContacted(false);
                setRequireVetContact(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDurationSubmit();
              }}
              color="primary"
              variant="outlined"
              disabled={requireVetContact && !vetContacted}
            >
              Submit Request
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default FastRequestForm;
