import { Box, Button, Typography } from '@mui/material';
import * as d3 from 'd3';

import { extent } from 'd3-array';
import { scaleLinear, scaleUtc } from 'd3-scale';
import { useState, useEffect, useRef } from 'react';
import { exportCSV, dimensionDefaults } from '../../Components/utils';
import { AxisBottom } from '../../Components/AxisBottom';
import { AxisLeft } from '../../Components/AxisLeft';
import { max } from 'd3-array';
export const ReportsOverTime = ({ data, dateFrom, dateTo, numcolumns, viewFilter }) => {
  /* STATE */
  // Data variables
  const [{ innerWidth, width, height, margin, yAxisWidth, innerHeight, xAxisHeight, xAxisLabelOffset }, setDimensions] =
    useState(dimensionDefaults);

  const [viewData, setViewData] = useState([]);
  const [exportData, setExportData] = useState([]);

  // D3 Scales
  const [xScale, setXScale] = useState(null);
  const [yScale, setYScale] = useState(null);
  const [line, setLine] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    if (data.length === 0) return;
    // Bar Chart Data

    const vbpData = reportsOverTime(data);
    console.log('processed data', vbpData);
    setViewData(vbpData);
    setExportData(vbpData);
    const yAxisWidth = 30;
    const innerHeight = 280;
    const width = (ref.current ? ref.current.parentElement.offsetWidth : 500) - 10;
    const innerWidth = width - margin.left - margin.right - yAxisWidth;
    setDimensions((prev) => ({
      ...prev,
      innerHeight,
      height: innerHeight + prev.margin.top + prev.margin.bottom + prev.xAxisHeight + prev.xAxisLabelOffset,
      width,
      innerWidth,
      yAxisWidth,
    }));

    // Define Bar Chart Scale Dimensions

    const scaleX = scaleUtc(
      d3.extent(vbpData, (d) => d.date),
      [margin.left, innerWidth]
    );

    const scaleY = scaleLinear([0, max(vbpData, (d) => d.count)], [innerHeight, 0]);

    const l = d3
      .line()
      .x((d) => scaleX(d.date))
      .y((d) => scaleY(d.count));

    setXScale(() =>
      scaleUtc(
        d3.extent(vbpData, (d) => d.date),
        [margin.left, innerWidth]
      )
    );
    setYScale(() => scaleLinear([0, max(vbpData, (d) => d.count)], [innerHeight, 0]));
    setLine(l(vbpData));
  }, [data, numcolumns, viewFilter]);

  // HELPER FUNCTIONS

  // Distill reports into counts per protocol
  const reportsOverTime = (arr) => {
    // Parse Date string
    const dateParse = d3.timeParse('%Y-%m-%d');
    const data = arr.map((d) => ({ ...d, dateSubmitted: dateParse(d.dateSubmitted.split('T')[0]) }));

    // Create an object with the counts
    return data.reduce((acc, obj) => {
      const item = acc.find((item) => {
        return item.date.getTime() === obj.dateSubmitted.getTime();
      });

      if (!item) {
        acc.push({ date: obj.dateSubmitted, count: 1 });
      } else {
        item.count += 1;
      }

      return acc;
    }, []);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" ref={ref}>
      <Box display="flex" justifyContent="space-between" width="90%" padding=".5em">
        <Typography variant="h5">SARs Over Time</Typography>
        <Button
          onClick={() => exportCSV('reportsOverTime', exportData, dateFrom, dateTo)}
          variant="contained"
          size="small"
        >
          Export
        </Button>
      </Box>
      <div style={{ width: '100%', height: '25em', overflow: 'auto' }}>
        <svg width={width} height={height} style={{ backgroundColor: 'DADFE1', overflow: 'visible' }}>
          <g transform={`translate(${margin.left + yAxisWidth},${margin.top})`}>
            {/* Left Axis */}
            {yScale &&
              yScale.ticks().map((tick, i) => {
                console.log('tick', tick);
                return (
                  <g transform={`translate(0, ${yScale(tick)})`} key={i}>
                    <text style={{ textAnchor: 'end', dominantBaseline: 'middle', maxWidth: '12em' }} x={0} dx="-1em">
                      {tick}
                      {/* {tick.length > protocolWidth ? tick.slice(0, protocolWidth - 3) + '...' : tick} */}
                    </text>
                  </g>
                );
              })}

            {/* Bottom Axis */}
            <text
              x={innerWidth / 2}
              y={innerHeight + xAxisHeight + xAxisLabelOffset}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="1.25em"
            >
              Number of Sick Animal Reports
            </text>

            {xScale &&
              xScale.ticks(5).map((tick, i) => (
                <g transform={`translate(${xScale(tick)},0)`} key={i}>
                  <line y2={innerHeight} stroke="black" />
                  <text style={{ textAnchor: 'middle' }} y={innerHeight + 1} dy="1em">
                    {`${tick.getMonth() + 1}/${tick.getDate()}`}
                  </text>
                </g>
              ))}
            {/* <AxisBottom xScale={xScale} innerHeight={innerHeight} /> */}

            {/* Data */}

            <path fill="none" stroke="steelblue" strokeWidth="1.5" d={line} />
          </g>
        </svg>
      </div>
    </Box>
  );
};
