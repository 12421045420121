import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const ConditionalClearInput = ({ section, effect, label }) => {
  return (
    <FormControlLabel
      className="checkbox"
      control={<Checkbox onChange={(e) => effect(e.target.checked)}></Checkbox>}
      label={label}
    ></FormControlLabel>
  );
};

ConditionalClearInput.defaultProps = {
  label: 'Save this section after submission',
};

export default ConditionalClearInput;
