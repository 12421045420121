import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ProtocolSearch from '../inputs/ProtocolSearch';
import SpecialConditionsTable from '../SpecialConditionsTable';
import StandardWrapper from '../design/StandardWrapper';

const SpecialNotificationConditionLookup = ({ user }) => {
  const [protocol, setProtocol] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [key, setKey] = useState(false);

  return submitted ? (
    <SpecialConditionsTable
      protocolNumber={protocol.label.split(':')[0]}
      PIName={protocol.label.split(':')[1].replace(' ', '')}
      onClose={() => setSubmitted(false)}
    />
  ) : (
    <StandardWrapper>
      <Typography variant="h1">Special Considerations Search</Typography>
      <br />
      <Typography variant="subtitle1">Select a Protocol to View Special Considerations</Typography>
      <br />

      <form onSubmit={() => setSubmitted(true)}>
        <Grid container justifyContent="center" spacing={2} alignItems="center" paddingInline="20%">
          <Grid item xs={12} sm={6}>
            <ProtocolSearch
              name="protocol"
              fullWidth
              required
              key={key}
              onSelectProtocol={setProtocol}
              val={protocol ? protocol.protocolNumber : undefined}
              allowFreeFill
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={!protocol}>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </StandardWrapper>
  );
};

export default SpecialNotificationConditionLookup;
