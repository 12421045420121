import React, { useState } from 'react';
import { gql } from '@apollo/client';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  useMediaQuery,
  Alert,
  AlertTitle,
  Box,
} from '@mui/material';
import ProtocolSearch from '../inputs/ProtocolSearch';
import BuildingSearch from '../inputs/BuildingSearch';
import SpeciesSearch from '../inputs/SpeciesSearch';
import HazardousSubstanceGroup from '../inputs/HazardousSubstanceGroup';
import EmailList from '../inputs/EmailList';
import PreferredVendorGroup from '../inputs/PreferredVendorGroup';
import ContactGroup from '../inputs/ContactGroup';
import { useMutation, useQuery } from '@apollo/client';

import moment from 'moment-timezone';
import FormDivider from '../design/FormDivider';
import { sleep, pigMatch, buildingNameToCode } from '../../utils';
import { DatePicker } from '@mui/x-date-pickers';
import StandardWrapper from '../design/StandardWrapper';
import { GET_TOASTS } from '../../global/graphql';
import { useEffect } from 'react';

const ORDER_MUTATION = gql`
  mutation SubmitAnimalOrder(
    $receivingProtocolNumber: String!
    $receivingPI: String
    $orderBillingString: String
    $perDiemBillingString: String
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String!
    $receivingContactEmergencyPhone: String
    $addlEmails: String
    $dateRequired: String
    $species: String!
    $strain: String
    $age: String
    $numMales: Int!
    $numFemales: Int!
    $numNoSex: Int!
    $numCages: Int
    $vendor: String
    $room: String
    $animalDescription: String
    $immunodeficient: Boolean!
    $hazardExposure: Boolean!
    $biohazardAgent: String
    $receivingBuilding: String!
    $lengthOfStay: String
    $addlRequirements: String
  ) {
    submitAnimalOrder(
      receivingProtocolNumber: $receivingProtocolNumber
      receivingPI: $receivingPI
      orderBillingString: $orderBillingString
      perDiemBillingString: $perDiemBillingString
      receivingContactName: $receivingContactName
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingContactEmergencyPhone: $receivingContactEmergencyPhone
      addlEmails: $addlEmails
      dateRequired: $dateRequired
      species: $species
      strain: $strain
      age: $age
      numMales: $numMales
      numFemales: $numFemales
      numNoSex: $numNoSex
      numCages: $numCages
      vendor: $vendor
      room: $room
      animalDescription: $animalDescription
      immunodeficient: $immunodeficient
      hazardExposure: $hazardExposure
      biohazardAgent: $biohazardAgent
      receivingBuilding: $receivingBuilding
      addlRequirements: $addlRequirements
      lengthOfStay: $lengthOfStay
    ) {
      success
      message
    }
  }
`;

const dateFormat = (date) => (date ? moment(date).format() : null);

const getMinDate = () => {
  let now = new Date().toLocaleString('en-us', { timeZone: 'America/Chicago' });
  let dt = new Date(Date.parse(now));
  let day = dt.getDay(); // 0 for Sunday ... 3 for Wednesday ... 6 for Satudary
  let hour = dt.getHours(); // 0 ... 16 for 4pm ... 23

  let beforeDeadline = day < 3 || (day === 3 && hour < 16);

  dt.setDate(dt.getDate() + ((((7 - day) % 7) + 1) % 7)); //Next Monday

  if (!beforeDeadline) {
    dt.setDate(dt.getDate() + ((((7 - day) % 7) + 1) % 7)); // Monday after next Monday
  }

  return dt;
};

const AnimalOrderForm = ({ user, onError }) => {
  const [userInfo] = useState(user);
  const [protocol, setProtocol] = useState();
  const [loading, setLoading] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [vars, setVars] = useState({
    receivingContactName: userInfo.firstName + ' ' + userInfo.lastName,
    receivingContactEmail: userInfo.emailAddress,
  });
  const [successToast, setSuccessToast] = useState();
  const [infoToasts, setInfoToasts] = useState([]);
  const [errorDialog, setErrorDialog] = useState();
  const [protocolWarning, setProtocolWarning] = useState();
  const [pigWarning, setPigWarning] = useState(false);
  const [pigWarningAcknowledged, setPigWarningAcknowledged] = useState(false);
  const [dateError, setDateError] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);
  const smallScreen = useMediaQuery('(max-width:710px)');

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: 'animalOrder' },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map((x) => ({ ...x, active: true })));
    }
  }, [toastsLoading, toastData?.toasts]);

  const disableInfoToast = (_id) => {
    setInfoToasts(
      infoToasts.map((x) => {
        if (_id === x._id) {
          return { ...x, active: false };
        }
        return { ...x };
      })
    );
  };

  // End info toast logic

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addOrder] = useMutation(ORDER_MUTATION, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setSuccessToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitAnimalOrder',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setSuccessToast(true);
        resetForm();
      } else setSuccessToast(false);
    },
  });

  const handleInput = (name, item) => {
    // console.log('----- handle submit -----');
    // console.log('name: ', name);
    // console.log('item: ', item);

    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const resetForm = () => {
    // Components are not responding to undefined values for clearing
    // Clear by replacing with "" before initializing other default values
    setVars({
      receivingContactName: userInfo.firstName + ' ' + userInfo.lastName,
      receivingContactEmail: userInfo.emailAddress,
      receivingPI: '',
      numMales: '',
      numFemales: '',
      numNoSex: '',
      immunodeficient: false,
      hazardExposure: false,
      receivingProtocolNumber: '',
      orderBillingString: '',
      perDiemBillingString: '',
      receivingContactPhone: '',
      receivingContactEmergencyPhone: '',
      species: '',
      strain: '',
      age: '',
      numCages: '',
      vendor: '',
      room: '',
      animalDescription: '',
      biohazardAgent: '',
      receivingBuilding: '',
      addlRequirements: '',
    });
    setProtocol();

    setFormReset(!formReset);

    document.getElementById('animal-order-form').reset();
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessToast(false);
    setErrorDialog(false);
    setProtocolWarning(false);
    setDateError();
    setPigWarning(false);
  };

  function isNumeric(str) {
    if (typeof str !== 'number' && typeof str !== 'string') return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const getNumAnimals = () => {
    let total = 0;
    if (isNumeric(vars.numFemales)) total += vars.numFemales;
    if (isNumeric(vars.numMales)) total += vars.numMales;
    if (isNumeric(vars.numNoSex)) total += vars.numNoSex;
    return total;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const variables = { ...vars };
    if (variables.dateRequired && !moment(variables.dateRequired).isValid()) {
      setDateError('Date must be MM/DD/YYYY');
      return;
    }
    setLoading(true);
    sleep(500);
    variables.dateRequired = dateFormat(variables.dateRequired);
    if (!variables.immunodeficient) variables.immunodeficient = false;
    if (!variables.hazardExposure) variables.hazardExposure = false;
    if (!variables.perDiemBillingString) variables.perDiemBillingString = variables.orderBillingString;
    if (variables.vendor === 'none') delete variables.vendor;
    if (variables.vendor === 'BRMS Breeding Core') {
      variables.species = 'Domestic Mouse';
      delete variables.emergencyContactPhone;
    }
    if (arrowProtocol && buildingNameToCode(variables.receivingBuilding)) {
      variables.receivingBuilding = buildingNameToCode(variables.receivingBuilding);
    }
    if (!variables.numMales) {
      variables.numMales = 0;
    }
    if (!variables.numFemales) {
      variables.numFemales = 0;
    }
    if (!variables.numNoSex) {
      variables.numNoSex = 0;
    }
    if (!variables.numCages) {
      delete variables.numCages;
    }
    // console.log('variables: ', variables);

    addOrder({ variables });
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">BRMS Animal Order</Typography>

      <Typography variant="h3">
        Animal orders must be submitted by 4:00 PM on Wednesday for delivery the following week.
      </Typography>
      <form
        id="animal-order-form"
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container alignContent="center" justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <FormDivider text="Order Information" />
          </Grid>
          <Grid item xs={12} sm={6}>
            {arrowProtocol ? (
              <ProtocolSearch
                fullWidth
                name="receivingProtocolNumber"
                key={formReset}
                onSelectProtocol={setProtocol}
                val={vars.receivingProtocolNumber}
                allowFreeFill
                sendInput={(name, value) => {
                  if (value.charAt(0) !== 'M') setProtocolWarning(true);
                  handleInput(name, value);
                }}
              />
            ) : (
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Protocol Number"
                placeholder="Enter Protocol Number"
                value={vars.receivingProtocolNumber}
                onChange={(e) => handleInput('receivingProtocolNumber', e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required={!arrowProtocol}
              variant="outlined"
              label={arrowProtocol ? 'PI' : 'Enter PI Name'}
              placeholder="PI Name"
              value={
                arrowProtocol
                  ? protocol && protocol.label && protocol.label.includes(': ')
                    ? protocol.label.split(': ')[1]
                    : ''
                  : vars.receivingPI
              }
              onChange={(e) => handleInput('receivingPI', e.target.value)}
              disabled={arrowProtocol}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  key={formReset}
                  checked={arrowProtocol}
                  onChange={(e) => {
                    setArrowProtocol(e.target.checked);
                    setProtocol();
                  }}
                />
              }
              label="ARROW Protocol"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              disablePast
              value={vars.dateRequired || null}
              inputVariant="outlined"
              label="Requested Delivery Date"
              key={dateError}
              format="MM/dd/yyyy"
              placeholder="Leave Blank for ASAP"
              onChange={(e) => {
                // console.log('e: ', e);
                if (!e) return;
                let date = new Date(Date.parse(e));

                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                const isTyped = date > Date.now();

                if (isWeekend && isTyped) setDateError('Animal orders cannot be requested to arrive on weekends.');
                else handleInput('dateRequired', dateFormat(e));
              }}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Grid>

          <ContactGroup
            key={formReset}
            userInfo={userInfo}
            sendInput={handleInput}
            breedingCore={vars.vendor === 'BRMS Breeding Core'}
            val={vars}
          />

          <PreferredVendorGroup key={formReset} sendInput={handleInput} val={vars} smallScreen={smallScreen} />

          <Grid item xs={12} sm={6}>
            <BuildingSearch
              name="receivingBuilding"
              freeSolo={!arrowProtocol}
              key={formReset}
              required
              protocol={protocol}
              val={vars.receivingBuilding}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Room"
              placeholder="Enter room number"
              value={vars.room}
              onChange={(e) => handleInput('room', e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Order Billing String"
              placeholder="Enter billing string"
              value={vars.orderBillingString}
              onChange={(e) => handleInput('orderBillingString', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Per Diem Billing String"
              placeholder="Enter billing string"
              value={vars.perDiemBillingString}
              onChange={(e) => handleInput('perDiemBillingString', e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Animal Information" />
          </Grid>

          {vars.vendor === 'BRMS Breeding Core' ? (
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                label="Number of Cages"
                value={vars.numCages}
                onChange={(e) => handleInput('numCages', parseInt(e.target.value))}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 1000,
                  },
                }}
              ></TextField>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <SpeciesSearch
                key={formReset}
                isRequired={vars.vendor !== 'BRMS Breeding Core'}
                protocol={protocol}
                val={vars}
                sendInput={(path, value) => {
                  if (!pigWarningAcknowledged && pigMatch(value)) {
                    setPigWarning(true);
                  } else if (pigWarningAcknowledged && !pigMatch(value)) {
                    setPigWarningAcknowledged(false);
                  }

                  handleInput(path, value);
                }}
                fromAnimalOrder={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              required={vars.vendor === 'BRMS Breeding Core'}
              variant="outlined"
              fullWidth
              label="Age or Weight"
              placeholder="Enter age"
              value={vars.age}
              onChange={(e) => handleInput('age', e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              variant="outlined"
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Total Males"
              value={vars.numMales}
              onChange={(e) => handleInput('numMales', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              variant="outlined"
              fullWidth
              type="number"
              label="Total Females"
              onWheel={(e) => e.target.blur()}
              value={vars.numFemales}
              onChange={(e) => handleInput('numFemales', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              variant="outlined"
              fullWidth
              type="number"
              label="Total No Sex Preference"
              onWheel={(e) => e.target.blur()}
              value={vars.numNoSex}
              onChange={(e) => handleInput('numNoSex', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={vars.vendor === 'BRMS Breeding Core' || ['Mouse', 'Rat'].includes(vars.species)}
              variant="outlined"
              fullWidth
              label={['Mouse', 'Rat'].includes(vars.species) ? 'Strain' : 'Breed or Strain'}
              placeholder="Enter strain"
              value={vars.strain}
              onChange={(e) => handleInput('strain', e.target.value)}
            ></TextField>
          </Grid>

          {vars.species && pigMatch(vars.species) && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  label="Length of Stay"
                  value={vars.lengthOfStay}
                  onChange={(e) => handleInput('lengthOfStay', e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Spacer */}
              </Grid>
            </>
          )}

          <Grid item xs={12} textAlign="left">
            <FormControlLabel
              control={
                <Checkbox
                  key={formReset}
                  checked={vars.immunodeficient}
                  onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                ></Checkbox>
              }
              label="Immunodeficient"
            ></FormControlLabel>
          </Grid>
          {vars.vendor === 'BRMS Breeding Core' && (
            <Grid item xs={12} textAlign="left">
              <HazardousSubstanceGroup key={formReset} val={vars} sendInput={handleInput} />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Description of animals"
              placeholder="Enter additional details about animals"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              className="to-clear"
              value={vars.animalDescription}
              onChange={(e) => handleInput('animalDescription', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required={vars.species && pigMatch(vars.species)}
              label="Special Requirements / Other Information"
              placeholder="Enter additional comments/instructions"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              className="to-clear"
              value={vars.addlRequirements}
              onChange={(e) => handleInput('addlRequirements', e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <FormDivider text="Other Contacts" />
          </Grid>

          <Grid item xs={12}>
            <EmailList
              key={formReset}
              sendInput={handleInput}
              existingContacts={namedContacts}
              instructions={
                'A copy of this document will be sent to the contacts provided above. You may add email addresses for additional contacts from sending or receiving groups.'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Our facilities do not typically exclude organisms considered to be part of the normal flora that may be
              present in animals received from approved vendors. If you are concerned about certain organisms and if
              they are present in the rodent strains you are requesting, please check the vendor's website for a
              complete listing of health status.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              style={{ width: 120, float: 'right' }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>

          <br></br>
        </Grid>
      </form>
      <Snackbar open={successToast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Animal Order has been submitted!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The order failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={protocolWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This tool is intended specifically for BRMS animal orders or orders being placed in the BRMS breeding core.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pigWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            As of January 1st 2021, a fee of $150 per animal will be assessed at the time of ordering to account for
            increased disposal costs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPigWarningAcknowledged(true);
              handleToastClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!dateError} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dateError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map((toast) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">{toast.message}</Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default AnimalOrderForm;
