import React from 'react';
import { Typography, Divider } from '@mui/material';

const FormDivider = ({ text }) => {
  return (
    <>
      <Typography variant="p" color="textSecondary" display="block" align="left" margin="1.5em 0 1em 0">
        {text}
        <Divider />
      </Typography>
    </>
  );
};

export default FormDivider;
