import React from 'react';
import { Grid, TextField } from '@mui/material';

const TransferContainerSection = ({ sendInput, strainRequired }) => {
  return (
    <Grid container spacing={2} columnSpacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Number of Cages / Tanks"
          type="number"
          onWheel={(e) => e.target.blur()}
          variant="outlined"
          onChange={(e) => sendInput('numCages', parseInt(e.target.value))}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required={strainRequired}
          variant="outlined"
          fullWidth
          label="Strain"
          placeholder="Enter strain"
          onChange={(e) => sendInput('strain', e.target.value)}
        ></TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Total Males"
          type="number"
          onWheel={(e) => e.target.blur()}
          variant="outlined"
          onChange={(e) => sendInput('numMales', parseInt(e.target.value))}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Total Females"
          type="number"
          onWheel={(e) => e.target.blur()}
          variant="outlined"
          onChange={(e) => sendInput('numFemales', parseInt(e.target.value))}
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default TransferContainerSection;
