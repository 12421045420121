import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Hidden,
  FormControlLabel,
  Checkbox,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import StandardWrapper from '../design/StandardWrapper';
import { KeyboardArrowLeft } from '@mui/icons-material';
import _ from 'lodash';

const APIKeyForm = ({ user }) => {
  const [text, setText] = useState();
  const [allClear, setAllClear] = useState(false);

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setText();
    setAllClear();
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const SEND_EMERGENCY_ALERT = gql`
    mutation name($text: String!, $allClear: Boolean) {
      sendEmergencyAlert(text: $text, allClear: $allClear)
    }
  `;
  const getVariables = () => {
    return {
      text,
      allClear,
    };
  };

  const [sendEmergencyAlert] = useMutation(SEND_EMERGENCY_ALERT, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'sendEmergencyAlert',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('Emergency Alert Sent!');
        resetForm();
      } else setToast();
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    sendEmergencyAlert({ variables });
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">BRMS Emergency Alert</Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          handleSubmit();
        }}
      >
        <Grid container justifyContent="space-between" spacing={2} padding="1em">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              key={key}
              required
              label="Alert Message"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={key}
                  checked={allClear}
                  onChange={(e) => {
                    setAllClear(e.target.checked);
                  }}
                ></Checkbox>
              }
              label="All Clear"
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" disabled={loading || !text} fullWidth>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to send alert. The BRMS IT office has been alerted of this issue, please alert appropriate staff by
            other means..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default APIKeyForm;
