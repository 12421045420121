import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  ListItem,
  Link,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
} from '@mui/material';

const Navbar = ({ logout, user, authorizedGroups }) => {
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  return (
    <div id="site-navbar">
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Link href="/" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
            <Typography variant="navbar" id="nav-title" paddingInline=".5em">
              Animal Health and Welfare
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }}>
          <List id="nav-list">
            <ListItem>
              <ListItemButton
                to="/"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                to="/animal-order"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>BRMS Animal Order</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                to="/internal-transfer"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>Transfer</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                to="/import"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>Import</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                to="/export"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>Export</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                to="/sds"
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemText>SDS</ListItemText>
              </ListItemButton>
            </ListItem>

            {user && (
              <ListItem>
                <ListItemButton
                  to="/resource-scheduler"
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  <ListItemText>Resource Scheduler</ListItemText>
                </ListItemButton>
              </ListItem>
            )}
            {user && (
              <ListItem>
                <ListItemButton
                  to="/user-notifications"
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  <ListItemText>User Notifications</ListItemText>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem>
              <ListItemButton
                to="/authentication-required"
                onClick={() => {
                  logout();
                  setDrawer(false);
                }}
              >
                <ListItemText>Log Out</ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText></ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Navbar;
