import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

const TimeRemaining = ({ tr }) => {
  const [countdown, setCountdown] = useState('calculating...');
  const [isOD, setIsOD] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = moment.duration(moment(tr).diff(moment()));
      setIsOD(diff.milliseconds() <= 0);
      const time = diff.abs();
      const days = time.days();
      const hours = time.hours();
      const minutes = time.minutes();

      setCountdown(
        `${days === 0 ? '' : days !== 1 ? `${days} days` : `${days} day`} ${
          hours === 0 ? '' : hours !== 1 ? `${hours} hours` : `${hours} hour`
        } ${minutes !== 1 ? `${minutes} minutes` : `${minutes} minute`}`
      );
    }, 1000);

    return () => clearInterval(interval);
  });

  return !isOD ? (
    <Typography fontSize=".9em">{countdown}</Typography>
  ) : (
    <Typography fontWeight="bold" sx={{ color: '#C5050C' }}>
      Overdue
    </Typography>
  );
};

export default TimeRemaining;
