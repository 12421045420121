import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
const StandardWrapper = ({ children, xl }) => {
  return (
    // <Grid container id="body-wrapper" justifyContent="center">
    //   <Grid item xs={12} md={10} lg={9} xl={!!xl ? 8 : 6}>
    //     <Paper
    //       style={{
    //         padding: '1em',
    //         marginBlock: '1em',
    //       }}
    //       elevation={3}
    //     >
    //       {children}
    //     </Paper>
    //   </Grid>
    // </Grid>
    <Box margin="auto" width={xl ? '100%' : 'min(90ch, 100%)'}>
      <Paper
        style={{
          padding: '1em',
          marginBlock: '1em',
        }}
        elevation={3}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default StandardWrapper;
