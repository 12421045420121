import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import { sleep } from '../../utils';
import { gql } from '@apollo/client';

const ACCESS_REQUEST_MUTATION = gql`
  mutation RequestAccess($emailAddress: String!, $details: String, $firstName: String!, $lastName: String!) {
    requestAccess(emailAddress: $emailAddress, details: $details, firstName: $firstName, lastName: $lastName) {
      firstName
      lastName
      displayName
    }
  }
`;

const RequestAccessForm = ({ user }) => {
  const [addAccessRequest] = useMutation(ACCESS_REQUEST_MUTATION, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        setVars({});
        setSubmitted(true);
      } else setToast(false);
    },
  });

  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [userInfo] = useState(user);
  const [vars, setVars] = useState({
    emailAddress: userInfo ? userInfo.emailAddress : '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  return !submitted ? (
    <>
      {/* Access request form */}
      <form
        id="request-access-form"
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          sleep(500);
          addAccessRequest({ variables: vars });
        }}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container justifyContent="center" spacing={2} columnSpacing={3} maxWidth="80ch" margin="auto">
          <Grid item xs={12}>
            <Typography variant="h2">Request Access </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              key={formReset}
              variant="outlined"
              fullWidth
              label="Your Email Address"
              placeholder="Enter email"
              type="email"
              value={vars.emailAddress || (user && user.emailAddress ? user.emailAddress : '')}
              onChange={(e) => {
                handleInput('emailAddress', e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Spacer */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="First Name"
              value={vars.firstName || ''}
              onChange={(e) => handleInput('firstName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Last Name"
              value={vars.lastName || ''}
              onChange={(e) => handleInput('lastName', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              key={formReset}
              variant="outlined"
              label="Optional Details"
              placeholder="Additional details including department, lab, what you would like to access, etc."
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              value={vars.details || ''}
              onChange={(e) => handleInput('details', e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Button
              style={{ width: 120, float: 'right' }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Success */}
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Access request has been submitted!
        </Alert>
      </Snackbar>

      {/* Non-specific error */}
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The access request failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <span>
      <Typography variant="h1">Access Request Submitted Successfully! </Typography>
      <Typography variant="body2">The BRMS IT office will review your request and respond to you shortly.</Typography>
    </span>
  );
};

export default RequestAccessForm;
