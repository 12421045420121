import React from 'react';
import { Grid, TextField } from '@mui/material';

import PhoneInput from './PhoneInput';

const SubmitterGroup = ({ userInfo, sendInput, addContact, passedKey, val }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label="Email"
          placeholder="Enter email"
          value={'submitterEmail' in val ? val.submitterEmail : userInfo.emailAddress}
          key={passedKey}
          onChange={(e) => {
            addContact(userInfo.firstName + ' ' + userInfo.lastName, e.target.value, 'submitter');
            sendInput('submitterEmail', e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {/* 
          <PhoneInput
          fullWidth
          isRequired={true}
          name="submitterPhone"
          label="Phone"
          sendInput={sendInput}
          val={val.submitterPhone}
          />
        */}
      </Grid>
    </>
  );
};

export default SubmitterGroup;
