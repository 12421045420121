import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import PhoneInput from './PhoneInput';

const ContactGroup = ({ userInfo, sendInput, breedingCore, val }) => {
  const [isBreedingCore, setIsBreedingCore] = useState(breedingCore ? breedingCore : false);

  useEffect(() => {
    setIsBreedingCore(breedingCore);
  }, [breedingCore]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="receivingContactName"
          value={val.receivingContactName || userInfo.firstName + ' ' + userInfo.lastName}
          label="Contact Name"
          placeholder="Enter name"
          onChange={(e) => sendInput(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneInput
          isRequired={true}
          name="receivingContactPhone"
          label="Contact Phone"
          sendInput={sendInput}
          val={val.receivingContactPhone || null}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="receivingContactEmail"
          label="Contact Email"
          value={'receivingContactEmail' in val ? val.receivingContactEmail : userInfo.emailAddress}
          placeholder="Enter email"
          type="email"
          onChange={(e) => sendInput(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {!isBreedingCore && (
          <PhoneInput
            isRequired={true}
            name="receivingContactEmergencyPhone"
            label="Emergency Contact Phone"
            sendInput={sendInput}
            val={val.receivingContactEmergencyPhone || null}
          />
        )}
      </Grid>
    </>
  );
};

export default ContactGroup;
