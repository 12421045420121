export const AxisBottom = ({ xScale, innerHeight }) => {
  return (
    <>
      {xScale.ticks(10).map((tick, i) => (
        <g transform={`translate(${xScale(tick)},0)`} key={i}>
          <line y2={innerHeight} stroke="black" />
          <text style={{ textAnchor: 'middle' }} y={innerHeight + 1} dy="1em">
            {tick}
          </text>
        </g>
      ))}
    </>
  );
};
