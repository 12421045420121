import { AxisBottom } from '../../Components/AxisBottom';
import { AxisLeft } from '../../Components/AxisLeft';

import { scaleBand, scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { useEffect, useState, useRef } from 'react';
import { dimensionDefaults, exportCSV } from '../../Components/utils';
import { Box, Button, Typography } from '@mui/material';

export const ReportsByProtocol = ({ data, dateFrom, dateTo, numcolumns, viewFilter }) => {
  /* STATE */
  // Data variables
  const [{ innerWidth, width, height, margin, yAxisWidth, innerHeight, xAxisHeight, xAxisLabelOffset }, setDimensions] =
    useState(dimensionDefaults);

  const [viewData, setViewData] = useState(data);
  const [exportData, setExportData] = useState([]);

  // D3 Scales
  const [xScale, setXScale] = useState(() => {});
  const [yScale, setYScale] = useState(() => {});

  const ref = useRef(null);

  useEffect(() => {
    // Bar Chart Data
    const vbpData = sdaReportsPerProtocol(data);
    setViewData(vbpData);
    setExportData(vbpData);

    const yAxisWidth = 80;
    const innerHeight = vbpData.length * 30;
    const width = (ref.current ? ref.current.parentElement.offsetWidth : 500) - 10;
    const innerWidth = width - margin.left - margin.right - yAxisWidth;
    setDimensions((prev) => ({
      ...prev,
      innerHeight,
      height: innerHeight + prev.margin.top + prev.margin.bottom + prev.xAxisHeight + prev.xAxisLabelOffset,
      width,
      innerWidth,
      yAxisWidth,
    }));

    // Define Bar Chart Scale Dimensions
    setYScale(() =>
      scaleBand()
        .domain(vbpData?.map((d) => d.protocolLabel))
        .range([0, innerHeight])
        .padding(0.1)
    );

    setXScale(() =>
      scaleLinear()
        .domain([0, max(vbpData, (d) => d.count)])
        .range([0, innerWidth])
    );
  }, [data, numcolumns, viewFilter]);

  // HELPER FUNCTIONS
  // Distill reports into counts per protocol
  const sdaReportsPerProtocol = (arr) => {
    // Create an object with the counts
    const reduction = arr.reduce((acc, obj) => {
      acc[obj.protocolNumber] = (acc[obj.protocolNumber] || 0) + 1;
      return acc;
    }, {});

    // Transform the counts object into an array
    const result = Object.keys(reduction).map((protocolLabel) => ({ protocolLabel, count: reduction[protocolLabel] }));
    result.sort((a, b) => b.count - a.count);
    return result;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" ref={ref}>
      <Box display="flex" justifyContent="space-between" width="90%" padding=".5em">
        <Typography variant="h5">SARs by Protocol</Typography>
        <Button
          onClick={() => exportCSV('reportsPerProtocol', exportData, dateFrom, dateTo)}
          variant="contained"
          size="small"
        >
          Export
        </Button>
      </Box>
      {xScale && yScale && viewData && (
        <div style={{ width: '100%', height: '25em', overflow: 'auto' }}>
          <svg width={width} height={height} style={{ backgroundColor: 'DADFE1', overflow: 'visible' }}>
            <g transform={`translate(${margin.left + yAxisWidth},${margin.top})`}>
              {/* Left Axis */}
              <AxisLeft yScale={yScale} />

              {/* Bottom Axis */}
              <text
                x={innerWidth / 2}
                y={innerHeight + xAxisHeight + xAxisLabelOffset}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="1.25em"
              >
                Number of Sick Animal Reports
              </text>
              <AxisBottom xScale={xScale} innerHeight={innerHeight} />

              {/* Data */}
              {viewData?.map((sar, i) => {
                return (
                  <rect
                    x={0}
                    y={yScale(sar['protocolLabel'])}
                    width={xScale(sar['count']) || 0}
                    height={yScale.bandwidth()}
                    fill="#c5050c"
                    key={i}
                  >
                    <title>{`Number of Reports: ${sar['count']}`}</title>
                  </rect>
                );
              })}
            </g>
          </svg>
        </div>
      )}
    </Box>
  );
};
